import type { TablesGenerated } from "../database.js";
import type { MergeDeep } from "type-fest";

type FeatureFlag = Pick<TablesGenerated<"feature_flags">, "key" | "value">;
type GeneratedProfile = TablesGenerated<"profiles">;
type Profile = MergeDeep<GeneratedProfile, {
    feature_flags?: Array<FeatureFlag>;
}>;
type FeatureFlags = Record<FeatureFlag["key"], FeatureFlag["value"]>;


function createFeatureFlags(profile: Profile): FeatureFlags {
    const flags = profile?.feature_flags?.map(({key, value}) => [key, value]) ?? [];
    return Object.fromEntries(flags);
}

export { createFeatureFlags }
