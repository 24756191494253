import nearley from "nearley";
import { exhaust } from "overtype";
import grammar from "../gen/grammar";
import lexer from "./lexer";

import { type Result, ok, isOk, err, unwrap, panic } from "./result";
import { DimTextParseError } from "./error";
import { Dimension } from "./ast";
import { defaultDimTextOptions, LinearDisplayFormat } from "./options";

import type { DimTextOptions, LinearDisplayOptions } from "./options";

type DimTextResult = Result<Dimension, DimTextParseError>;

class DimText {
  static grammar = nearley.Grammar.fromCompiled(grammar);
  options: DimTextOptions;

  constructor(options: Partial<DimTextOptions> = {}) {
    this.options = { ...defaultDimTextOptions, ...options };
  }

  parse(value: string): DimTextResult {
    try {
      const parser = new nearley.Parser(DimText.grammar, {
        lexer: lexer(this.options),
      });

      let result = parser.feed(value.trim()).results[0];

      if (!result) {
        const err = new Error("Unexpected end of input");
        // @ts-ignore
        err.offset = parser.current;
        throw err;
      }

      if (parser.results.length > 1) {
        const err = new Error("Ambiguous result");
        // @ts-ignore
        err.offset = parser.current; // @ts-ignore
        err.results = parser.results;
        throw err;
      }

      const length = result.length;
      const last = result[length - 1];

      if (last.unit === "default") {
        if (length === 2) {
          last.unit = "inches";
        } else {
          last.unit = this.options.defaultUnit;
        }
      }

      if (length === 2 && last.unit !== "inches") {
        const err = new Error(
          "Inconsistent units: two-part quantity must be feet and inches"
        );
        // @ts-ignore
        err.offset = parser.current;
        // @ts-ignore
        err.results = parser.results;
        throw err;
      }

      return ok(result);
    } catch (parseError: any) {
      const { message, offset, token } = parseError;
      return err(
        new DimTextParseError(message, {
          cause: parseError,
          offset,
          token,
          input: value,
        })
      );
    }
  }

  /**
   * Parse `value` and return a `Dimension` value if it is valid;
   * throw otherwise.
   *
   * Use this method for dimtext string that are statically known to be valid
   *
   * @param value String to parse
   */
  parseUnsafe(value: string): Dimension {
    const result = this.parse(value);
    if (isOk(result)) {
      return unwrap(result);
    }
    return panic(result);
  }

  // TODO: should this preferably return a Result<string>?
  unparse(obj: DimTextResult | Dimension): string {
    if (obj && "ok" in obj) {
      if (obj.ok) {
        return obj.value.toString();
      } else {
        return obj.err.input;
      }
    } else if (obj instanceof Dimension) {
      return obj.toString();
    } else {
      exhaust(obj, "unparse: unexpected input");
    }
  }

  format(
    obj: DimTextResult | Dimension,
    options: LinearDisplayOptions = {}
  ): string {
    if (obj && "ok" in obj && !obj.ok) {
      return panic(obj); // throws
    } else if (obj) {
      const dim = unwrap(obj);

      let format = this.options.displayFormat;
      const precision = this.options.displayPrecision;
      let displayOptions = { ...this.options.displayOptions, ...options };

      switch (format) {
        case LinearDisplayFormat.ARCHITECTURAL:
        case LinearDisplayFormat.ENGINEERING:
          break;
        case LinearDisplayFormat.DECIMAL:
        case LinearDisplayFormat.FRACTIONAL:
          displayOptions.displayUnit ||= this.options.defaultUnit;
          break;
      }

      return dim.format(format, precision, displayOptions);
    } else {
      exhaust(obj, "format: unexpected input");
    }
  }
}

export default DimText;
export * from "./ast";
export { DimText, DimTextParseError };
export { formatFraction } from "./frac";
export { LinearDisplayFormat } from "./options";

export type { DimTextResult }
export type { DimTextOptions, LinearDisplayPrecision, LinearDisplayOptions } from "./options";
