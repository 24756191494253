import type { Length as ConvertibleLength, /* Angle as ConvertibleAngle */ } from "convert";
import type { ParseableUnit } from "./lexer";
import type { FormatFractionOptions } from "./frac";

type Length = ConvertibleLength & ParseableUnit;
// type Angle = ConvertibleAngle & ParseableUnit

// prettier-ignore
/**
 * Autocad: [LUNITS]{@link https://knowledge.autodesk.com/support/autocad/learn-explore/caas/CloudHelp/cloudhelp/2022/ENU/AutoCAD-Core/files/GUID-D7C80D1F-B1C0-44A9-898E-B3100FF391CB-htm.html?st=LUNITS}
 * [See also[{@link https://www.dummies.com/article/technology/software/design-software/autocad/choosing-units-autocad-221298/}
 */
enum LinearDisplayFormat {
  // SCIENTIFIC = 1,  // Don't implement yet
  DECIMAL = 2,        // 15.5
  ENGINEERING = 3,    // 1' 3.5"
  ARCHITECTURAL = 4,  // 1' 3 1/2"
  FRACTIONAL = 5,     // 15 1/2
}

/**
 * AutoCAD: [LUPREC]{@link https://knowledge.autodesk.com/support/autocad/learn-explore/caas/CloudHelp/cloudhelp/2022/ENU/AutoCAD-Core/files/GUID-5FFF39D6-EFC7-49F5-B56A-6023EB5C0DE7-htm.html?st=LUPREC}
 */
type LinearDisplayPrecision = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

/**
 * AutoCAD: [AUNITS]{@link https://knowledge.autodesk.com/support/autocad/learn-explore/caas/CloudHelp/cloudhelp/2022/ENU/AutoCAD-Core/files/GUID-C7C0F6A5-7982-43DB-97F9-5B9B0044E9FA-htm.html?st=aunits}
 */
enum AngularDisplayFormat {
  DECIMAL_DEGREES = 0,
  DEGREES_MINUTES_SECONDS = 1,
  // GRADIANS = 2,
  // RADIANS = 3,
  // SURVEYORS_UNITS = 4,
}

/**
 * AutoCAD: [AUPREC]{@link https://knowledge.autodesk.com/support/autocad/learn-explore/caas/CloudHelp/cloudhelp/2022/ENU/AutoCAD-Core/files/GUID-EE1ED20C-1096-4299-820F-83F1BC9B96F3-htm.html?st=AUPREC}
 */
type AngularDisplayPrecision = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

interface DimTextOptions {
  defaultUnit: Length;

  allowImplicitUnits: boolean;
  allowImplicitMixedFractions: boolean;
  displayFormat: LinearDisplayFormat;
  displayPrecision: LinearDisplayPrecision;
  displayOptions: LinearDisplayOptions;
  /* Not yet implemented:
  radixPoint?: "." | "," | "any",
  allowImproperFractions?: boolean,
  maxDenominatorDigits?: number,
  */
}

type ImperialUnitSymbolFormat = "abbrev" | "ascii" | "unicode";

/*
export type ImperialDisplayFormat = LinearDisplayFormat.ARCHITECTURAL | LinearDisplayFormat.ENGINEERING;
export type GeneralDisplayFormat = LinearDisplayFormat.DECIMAL | LinearDisplayFormat.FRACTIONAL;

export type LinearDisplayOptions<T> = {
  displayUnit?: T extends GeneralDisplayFormat ? Length : never,
  showUnit?: T extends GeneralDisplayFormat ? boolean : never,
  unitSpacing?: "before" | "after" | "both" | true | false,
  unitSymbols?: T extends ImperialDisplayFormat ? ImperialUnitSymbolFormat : never,
};
*/

interface LinearDisplayOptions extends FormatFractionOptions {
  displayUnit?: Length;
  showUnit?: boolean;
  unitSpacing?: "before" | "after" | "both" | true | false;
  unitSymbols?: ImperialUnitSymbolFormat;
}

const defaultDimTextOptions: DimTextOptions = {
  defaultUnit: "inches",
  allowImplicitUnits: true,
  allowImplicitMixedFractions: true,
  displayFormat: LinearDisplayFormat.DECIMAL,
  displayPrecision: 2,
  displayOptions: {
    displayUnit: "inches",
    fractionSeparator: "dash",
  },
};
export {
  defaultDimTextOptions,
  LinearDisplayFormat,
  AngularDisplayFormat
};

export type {
  LinearDisplayPrecision,
  AngularDisplayPrecision,
  DimTextOptions,
  ImperialUnitSymbolFormat,
  LinearDisplayOptions
};
