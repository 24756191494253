import { createClient, drawingSrcGetter } from "@local/lamina-core";
import Bugsnag from "src/bugsnag.js";
import {
  COOKIE_DOMAIN as cookieDomain,
  SUPABASE_URL as supabaseUrl,
  TRIOPS_URL as triopsUrl,
  SUPABASE_ANON_KEY as supabaseAnonKey,
  BACKEND_PREFIX as backendPrefix,
  WEBSOCKET_URL,
} from "src/env";
import getUrlParameterByName from "src/extensions/dom/get-url-parameter-by-name";

// If there is an error in the URL hash, strip it from the URL (to avoid
// auto-logout by the supabase client).
const errorParameter = getUrlParameterByName("error_description");
if (errorParameter) {
  const url = new URL(location.href);
  url.hash = "";
  history.replaceState(null, null, url);
}

// Run `yarn db:gen:types` to update type definitions.
const api = createClient(supabaseUrl, supabaseAnonKey, {
  cookies: {
    domain: cookieDomain,
  },
});

// If server restarts, force reload to ensure that user has latest version.
let serverID;
export const ws = new WebSocket(WEBSOCKET_URL);
function connect() {
  ws.onmessage = (e) => {
    const { appID } = JSON.parse(e.data);
    console.log("[app] Connection with server:", appID);
    if (!serverID) {
      serverID = appID;
    } else if (serverID !== appID) {
      location.reload();
    }
  };

  ws.onclose = (e) => {
    console.log(
      "Connection with server is closed. Reconnect will be attempted in 1 seconds.",
    );
    setTimeout(function () {
      connect();
    }, 2000);
  };

  ws.onerror = (err) => {
    ws.close();
  };
}
connect();

/**
 *
 * @param {string | undefined} token
 * @returns {ReturnType<jsonHeaders> & { Authorization?: string }}
 */
function headers(token) {
  const headers = jsonHeaders();
  if (typeof token === "string") {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
}

function jsonHeaders() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

async function jsonResult(res) {
  const contentType = res.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return res.json();
  }

  if (res.ok) {
    return { status: res.status, message: res.statusText };
  }

  return { error: { status: res.status, message: res.statusText } };
}

async function updateProp(table, id, prop, ...args) {
  try {
    if (typeof prop === "object") {
      const { data, error } = await api
        .from(table)
        .update({ ...prop, commit_id: args[0] })
        .eq("id", id);

      if (error) throw error;
      return data;
    } else {
      // prop is a string
      const { data, error } = await api
        .from(table)
        .update({ [prop]: args[0], commit_id: args[1] })
        .eq("id", id);

      if (error) throw error;
      return data;
    }
  } catch (error) {
    const err = new Error(
      `Supabase api error updating table: ${table}, id: ${id}, prop: ${JSON.stringify(prop)}`,
      { cause: error },
    );
    Bugsnag.notify(err);
    console.log(err);
  }
}

async function addRecords(table, records, commit_id) {
  const recordsWithCommitId = commit_id
    ? records.map((i) => ({ ...i, commit_id }))
    : records;

  try {
    const { data, error } = await api.from(table).insert(recordsWithCommitId);

    if (error) throw error;

    return data;
  } catch (error) {
    const err = new Error(`Supabase api error inserting into: ${table}`, {
      cause: error,
    });
    Bugsnag.notify(err);
    console.log(err);
  }
}

async function removeRecords(table, ids) {
  try {
    const { data, error } = await api.from(table).delete().in("id", ids);

    if (error) throw error;

    return data;
  } catch (error) {
    const err = new Error(`Supabase api error deleting from: ${table}`, {
      cause: error,
    });
    Bugsnag.notify(err);
    console.log(err);
  }
}

// Special function is required for items because the list of items can be long,
// and PostgREST currently only supports enumerating ids in the URI. If they update
// to allow posting a body, this function can be removed.
/**
 *
 * @param {Array<string>} item_ids
 */
async function removeItems(item_ids) {
  try {
    const { data, error } = await api.rpc("delete_items", { item_ids });
    if (error) throw error;

    return data;
  } catch (error) {
    const err = new Error(`Supabase api error calling delete_items rpc`, {
      cause: error,
    });
    Bugsnag.notify(err);
    console.log(err);
  }
}

async function checkBackendHealth() {
  const res = await fetch(`${backendPrefix}/healthz`);
  if (res.ok) {
    return res.text();
  } else {
    return null;
  }
}

async function requestDemo(email) {
  const res = await fetch(`${backendPrefix}/request-demo`, {
    method: "POST",
    headers: jsonHeaders(),
    body: JSON.stringify({ email, context: "app" }),
  });

  return res;
}

async function addUser(user) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/users`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify({ user }),
  });

  return res.json();
}

async function addContact(contact) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/contacts`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify(contact),
  });

  return jsonResult(res);
}

async function addCustomer(customer) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/customers`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify(customer),
  });

  return jsonResult(res);
}

async function addRep(rep) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/reps`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify(rep),
  });

  return jsonResult(res);
}

async function resendCustomerInvitation(customer) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/customers/resend-invitation`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify(customer),
  });

  return jsonResult(res);
}

async function resendRepInvitation(rep) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/reps/resend-invitation`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify(rep),
  });

  return jsonResult(res);
}

async function addGuest(guest) {
  const res = await fetch(`${backendPrefix}/guests`, {
    method: "POST",
    body: JSON.stringify(guest),
    headers: jsonHeaders(),
  });

  return jsonResult(res);
}

async function sendMagicLink(id) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;
  fetch(`${backendPrefix}/magiclink`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify({ id }),
  });
}

async function signIn({ email, redirectTo }) {
  const res = await fetch(`${backendPrefix}/signin`, {
    method: "POST",
    headers: jsonHeaders(),
    body: JSON.stringify({ email, redirectTo }),
  });

  return jsonResult(res);
}

async function getGroupFromLink({ link_id }) {
  const res = await fetch(`${backendPrefix}/links/group`, {
    method: "POST",
    body: JSON.stringify({ link_id }),
    headers: jsonHeaders(),
  });

  return res.json();
}

async function createSampleRfq({ organization_id }) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/sample`, {
    method: "POST",
    body: JSON.stringify({ organization_id }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

async function getRfqFromLink({ id, email = null, organization_id = null }) {
  const { data } = await api.auth.getSession();
  const h = data.session ? headers(data.session.access_token) : jsonHeaders();

  const res = await fetch(`${backendPrefix}/requests/get`, {
    method: "POST",
    body: JSON.stringify({ id, email, organization_id }),
    headers: h,
  });

  return jsonResult(res);
}

async function submitQuote(request) {
  const { data } = await api.auth.getSession();
  const h = data.session ? headers(data.session.access_token) : jsonHeaders();
  delete h["Content-Type"];

  const { attachments, ...rest } = request;
  const attachmentJson = attachments.map((a) => {
    const { data, ...rest } = a;
    return rest;
  });

  const body = new FormData();
  body.append(
    "json_data",
    JSON.stringify({ ...rest, attachments: attachmentJson }),
  );
  attachments.forEach((a) => {
    body.append("quotes", new Blob([a.data]), a.name);
  });

  const res = await fetch(`${backendPrefix}/requests/submit`, {
    method: "POST",
    body,
    headers: h,
  });

  return jsonResult(res);
}

async function submitQuoteOnly({
  quote_id,
  sender_id,
  price,
  notes,
  days_valid,
  attachments,
}) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/submit-quote-only`, {
    method: "POST",
    body: JSON.stringify({
      quote_id,
      sender_id,
      price,
      notes,
      days_valid,
      attachments,
    }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

async function reviseQuote({
  quote_id,
  sender_id,
  price,
  notes,
  days_valid,
  attachments,
}) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/revise-quote`, {
    method: "POST",
    body: JSON.stringify({
      quote_id,
      sender_id,
      price,
      notes,
      days_valid,
      attachments,
    }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

async function shareDraft({ job_id, buyer_id, contact_id }) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/share-draft`, {
    method: "POST",
    body: JSON.stringify({
      job_id,
      buyer_id,
      contact_id,
    }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

async function processRfq({ job_id, buyer_id, request_for, buyer_reference }) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/requests/process`, {
    method: "POST",
    body: JSON.stringify({
      job_id,
      buyer_id,
      request_for,
      buyer_reference,
    }),
    headers: headers(access_token),
  });

  return jsonResult(res);
}

async function getPasswordResetLink({ email, redirectTo }) {
  const res = await fetch(`${backendPrefix}/resetpassword`, {
    method: "POST",
    body: JSON.stringify({ email, redirectTo }),
    headers: jsonHeaders(),
  });

  return res.json();
}

async function setUserPassword({ id, password }) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(`${backendPrefix}/resetpassword/set`, {
    method: "POST",
    headers: headers(access_token),
    body: JSON.stringify({ id, password }),
  });

  return jsonResult(res);
}

async function sendQuoteRequest(request) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/requests/send`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify(request),
  });

  return jsonResult(res);
}

async function addQuoteRequestRecipients(request) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/requests/add-recipients`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify(request),
  });

  return jsonResult(res);
}

async function cancelQuoteRequest({ id }) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/requests/cancel`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify({ id }),
  });

  return jsonResult(res);
}

async function cancelQuote({ quote_id }) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/requests/cancel-quote`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify({ quote_id }),
  });

  return jsonResult(res);
}

async function storeObject(group, bucket, object, contentType, file) {
  const { data } = await api.auth.getSession();
  const { access_token } = data.session;

  const res = await fetch(
    `${backendPrefix}/storage/object/${group}/${bucket}/${encodeURIComponent(object)}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": contentType,
        Authorization: `Bearer ${access_token}`,
      },
      body: file,
    },
  );

  return jsonResult(res);
}

async function checkSuprastoreStatus(bucket, path) {
  const { data } = await api.auth.getSession();

  const res = await fetch(
    `${backendPrefix}/storage/suprastore/status/${bucket}/${path}`,
    {
      method: "GET",
      headers: headers(data.session.access_token),
    },
  );
  return jsonResult(res);
}

const drawingSrc = drawingSrcGetter(supabaseUrl, triopsUrl);

async function checkContact(request) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/contacts/check`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify(request),
  });

  return jsonResult(res);
}

async function sendInvitation(request) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/invitations/`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify(request),
  });

  return jsonResult(res);
}

async function useInvitation(request) {
  const res = await fetch(`${backendPrefix}/invitations/use`, {
    method: "POST",
    headers: jsonHeaders(),
    body: JSON.stringify(request),
  });

  return jsonResult(res);
}

async function resendInvitation(request) {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/invitations/resend`, {
    method: "POST",
    headers: headers(data.session.access_token),
    body: JSON.stringify(request),
  });

  return jsonResult(res);
}

async function reloadClients() {
  const { data } = await api.auth.getSession();

  const res = await fetch(`${backendPrefix}/appid`, {
    method: "POST",
    headers: headers(data.session.access_token),
  });

  return jsonResult(res);
}

/**
 * @param {string} [orgId]
 */
async function getSupportVideos(orgId) {
  const {
    data: { session },
  } = await api.auth.getSession();

  let url = `${backendPrefix}/support/videos`;

  if (typeof orgId === "string") {
    url = `${url}/${orgId}`;
  }

  const res = await fetch(url, {
    method: "GET",
    headers: headers(session?.access_token),
  });

  return jsonResult(res);
}

export {
  api,
  updateProp,
  addRecords,
  removeRecords,
  removeItems,
  requestDemo,
  addUser,
  addContact,
  addCustomer,
  addRep,
  resendCustomerInvitation,
  resendRepInvitation,
  addGuest,
  checkContact,
  sendMagicLink,
  getPasswordResetLink,
  setUserPassword,
  signIn,
  getGroupFromLink,
  createSampleRfq,
  getRfqFromLink,
  submitQuote,
  submitQuoteOnly,
  reviseQuote,
  shareDraft,
  processRfq,
  sendQuoteRequest,
  addQuoteRequestRecipients,
  cancelQuoteRequest,
  cancelQuote,
  storeObject,
  checkSuprastoreStatus,
  checkBackendHealth,
  drawingSrc,
  sendInvitation,
  useInvitation,
  resendInvitation,
  reloadClients,
  getSupportVideos,
};
