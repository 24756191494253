import get from "lodash/get.js";
import set from "lodash/set.js";
import { almostEqual } from "overline";
import { DimText, Dimension, Quantity, LinearDisplayFormat } from "dimtext";

import Polyface from "@local/extensions/geometry/polyface";
import { sortArrayBy } from "@local/extensions/collections/sortable-list.js";
import parseDim from "@local/extensions/parsers/parse-dim.js";
import unparseDim from "@local/extensions/parsers/unparse-dim.js";
import parseInteger from "@local/extensions/parsers/parse-pos-integer.js";
import dimSettings from "@local/extensions/utilities/dim-settings.js";
import { nextMark } from "@local/extensions/identifiers/mark.js";
import { rotatePoint } from "@local/extensions/geometry/transform-sheet-points.js";
import isRectangle from "@local/extensions/geometry/is-rectangle.js";
import cwVertices from "@local/extensions/geometry/cw-vertices.js";

import type { Features, Fabricated } from "./feature.js";
import type { Shape, Shaped } from "./shape.js";
import type { PlainDimension, DimTextUnit } from "dimtext";


interface ItemRecordCore extends Fabricated, Shaped {
  width: PlainDimension | null;
  height: PlainDimension | null;
  width_offset: PlainDimension | null;
  height_offset: PlainDimension | null;
  rectangle_offset: {
    x: number,
    y: number
  } | null;
  data: {
    fabrications?: Features,
  },
  shape: Shape;
  custom_column_data: Record<string, string>;
}

const dt = new DimText();

function createItem(groupId, userId, items = [], types = [], properties) {
  const mark = nextMark(items);

  const data = {};
  const props = {};
  if (properties) {
    Object.entries(properties).forEach(([key, value]) => {
      const [col, ...path] = key.split(".");
      if (col === "data") {
        set(data, path, value);
      } else {
        props[col] = value;
      }
    });
  }

  return {
    id: crypto.randomUUID(),
    group_id: groupId,
    created_by: userId,
    created_at: new Date(),
    width: null,
    width_offset: dt.parseUnsafe("0"),
    height: null,
    height_offset: dt.parseUnsafe("0"),
    depth: dt.parseUnsafe("0.1"),
    quantity: 1,
    mark,
    approval_status: "none",
    data,
    shape: { type: "rect" },
    rectangle_offset: {},
    custom_column_data: {},
    ...props,
  };
}

function updateItemCache(item, group) {
  item.cache = {
    width_in: item.width?.toNumber("inches"),
    height_in: item.height?.toNumber("inches"),
    width_offset_in: item.width_offset.toNumber("inches"),
    height_offset_in: item.height_offset.toNumber("inches"),
  };

  const polyface = new Polyface(item, group?.data?.fabrications);

  item.cache.shapeWithFeatures = polyface.shapeWithFeatures;
  item.cache.offsets = polyface.offsets;
  item.cache.bbox = polyface.bbox;
  item.cache.width = item.cache.bbox.width;
  item.cache.height = item.cache.bbox.height;

  // Set width/height prime (i.e., the overall width/height inc. offsets)
  if (!item.width || !item.height) {
    item.cache.width_prime_in = item.width
      ? item.cache.width_in + item.cache.width_offset_in
      : null;
    item.cache.width_prime = item.width
      ? new Dimension(new Quantity(item.cache.width_prime_in, "inches"))
      : null;
    item.cache.height_prime_in = item.height
      ? item.cache.height_in + item.cache.height_offset_in
      : null;
    item.cache.height_prime = item.height
      ? new Dimension(new Quantity(item.cache.height_prime_in, "inches"))
      : null;
    item.cache.perimeter_in = null;
  } else if (item.shape.type === "free") {
    item.cache.width_prime_in = item.cache.bbox.width;
    item.cache.width_prime = new Dimension(
      new Quantity(item.cache.bbox.width, "inches"),
    );
    item.cache.height_prime_in = item.cache.bbox.height;
    item.cache.height_prime = new Dimension(
      new Quantity(item.cache.bbox.height, "inches"),
    );
    item.cache.perimeter_in = polyface.perimeter;
  } else {
    item.cache.width_prime_in =
      item.cache.width_in + item.cache.width_offset_in;
    item.cache.width_prime = new Dimension(
      new Quantity(item.cache.width_prime_in, "inches"),
    );
    item.cache.height_prime_in =
      item.cache.height_in + item.cache.height_offset_in;
    item.cache.height_prime = new Dimension(
      new Quantity(item.cache.height_prime_in, "inches"),
    );
    item.cache.perimeter_in =
      item.cache.width_prime_in * 2 + item.cache.height_prime_in * 2;
  }

  item.cache.edges = polyface.edges;
  item.cache.vertices = polyface.vertices;

  if (item.width && item.height) {
    // (stored in sq. ft)
    item.cache.area =
      item.cache.width * item.cache.height * item.quantity * 0.0069444444444444;
  }

  const type = group?.types[item.type_id];
  if (type && type.data.layers) {
    item.cache.thickness = type.cache.total_thickness;

    let totalGlassThickness;

    const glassLayers = type.data.layers.filter((l) => l.type === "glass");
    if (glassLayers.some((l) => !l.material?.data?.thickness)) {
      totalGlassThickness = null;
    } else {
      totalGlassThickness = glassLayers.reduce((t, l) => {
        return t + l.material.data.thickness.toNumber("inches");
      }, 0);
    }

    /*
      Assumption: glass weight is 2.52 g/cm^3 or 0.09104078 lbs/in^3
      https://www.google.com/search?client=firefox-b-1-d&q=2.52+g%2Fcm%5E3+in+lbs%2Fin%5E3
    */

    if (
      item.width &&
      item.height &&
      glassLayers.length &&
      totalGlassThickness !== null
    ) {
      item.cache.weight =
        totalGlassThickness *
        item.cache.width *
        item.cache.height *
        0.09104078 *
        item.quantity;
    } else {
      item.cache.weight = null;
    }
  }

  return item;
}

function hydrateItem(item, group) {
  item.width = item.width && new Dimension(item.width);
  item.height = item.height && new Dimension(item.height);
  item.width_offset = new Dimension(item.width_offset);
  item.height_offset = new Dimension(item.height_offset);

  if (item.data.fabrications?.voids) {
    item.data.fabrications.voids.forEach((v) => {
      if (v.type === "circular-hole") {
        v.diameter = new Dimension(v.diameter);
      } else if (v.type === "rectangular-hole") {
        v.width = new Dimension(v.width);
        v.height = new Dimension(v.height);
        v.radius = new Dimension(v.radius);
      }

      v.reference.length = new Dimension(v.reference.length);
      v.reference.offset = new Dimension(v.reference.offset);
    });
  }

  if (item.data.fabrications?.bug) {
    const bug = item.data.fabrications.bug;
    bug.width = new Dimension(bug.width);
    bug.height = new Dimension(bug.height);
    bug.reference.length = new Dimension(bug.reference.length);
    bug.reference.offset = new Dimension(bug.reference.offset);
  }

  if (item.data.fabrications?.edge) {
    item.data.fabrications.edge.forEach((f) => {
      f.reference.length = new Dimension(f.reference.length);
    });
  }

  if (item.data.reference_planes) {
    if (item.data.reference_planes.type === "box") {
      item.data.reference_planes.left.value = new Dimension(
        item.data.reference_planes.left.value,
      );
      item.data.reference_planes.right.value = new Dimension(
        item.data.reference_planes.right.value,
      );
      item.data.reference_planes.bottom.value = new Dimension(
        item.data.reference_planes.bottom.value,
      );
      item.data.reference_planes.top.value = new Dimension(
        item.data.reference_planes.top.value,
      );
    } else if (item.data.reference_planes.type === "cross") {
      item.data.reference_planes.horizontal.value = new Dimension(
        item.data.reference_planes.horizontal.value,
      );
      item.data.reference_planes.vertical.value = new Dimension(
        item.data.reference_planes.vertical.value,
      );
    }
  }

  updateItemCache(item, group);

  return item;
}

/**
 * @param group Group
 * @param Types
 * @param disabled
 * @param orgColumns Org-level columns
 * @param jobColumns Job-level columns
 * @param sc Standard columnns
 */
function makeColumns(
  group,
  t: Array<any>,
  disabled: boolean,
  orgColumns,
  jobColumns,
  sc,
  extraColumns = [],
) {
  t = t || [];

  const { displayUnit, dimFormat, dimPrecision } = dimSettings(
    group.data.settings,
  );

  const dimParser = parseDim(displayUnit);

  const areaFormatter = (a) => {
    return displayUnit === "inches"
      ? typeof a === "number"
        ? a.toFixed(2)
        : ""
      : typeof a === "number"
        ? (a * 0.092903).toFixed(2)
        : "";
  };

  const dimFormatter = (v) => {
    return v
      ? v.format(LinearDisplayFormat[dimFormat], dimPrecision, { displayUnit })
      : "";
  };

  const standardCols = [
    { label: "Mark", prop: "mark", readOnly: disabled, type: "string" },
    {
      label: "Width",
      type: "multi-column",
      id: "width",
      prop: "width",
      primary: 0,
      result: 2,
      separateHeaders: true,
      hideSubcolumns: group.data.settings.hide_width_offset,
      subcolumns: [
        {
          prop: "width",
          readOnly: disabled,
          parser: dimParser,
          formatter: dimFormatter,
          unparser: unparseDim,
          validator: (v, rd) => {
            if (rd?.shape.type === "none") return v === null;
            return v === null || !!v;
          },
          highlighter: (v, rd) => {
            if (rd?.shape.type === "none") return v !== null;
            return v === null;
          },
          default: null,
        },
        {
          label: "+/-",
          prop: "width_offset",
          readOnly: disabled,
          parser: dimParser,
          formatter: dimFormatter,
          unparser: unparseDim,
          validator: (v) => !!v,
          default: () => dimParser("0"),
        },
        {
          label: "=",
          prop: "cache.width_prime",
          readOnly: true,
          formatter: dimFormatter,
          unparser: unparseDim,
        },
      ],
    },
    {
      label: "Height",
      type: "multi-column",
      id: "height",
      prop: "height",
      primary: 0,
      result: 2,
      separateHeaders: true,
      hideSubcolumns: group.data.settings.hide_height_offset,
      subcolumns: [
        {
          prop: "height",
          readOnly: disabled,
          parser: dimParser,
          formatter: dimFormatter,
          unparser: unparseDim,
          validator: (v, rd) => {
            if (rd?.shape.type === "none") return v === null;
            return v === null || !!v;
          },
          highlighter: (v, rd) => {
            if (rd?.shape.type === "none") return v !== null;
            v === null
          },
          default: null,
        },
        {
          label: "+/-",
          prop: "height_offset",
          readOnly: disabled,
          parser: dimParser,
          formatter: dimFormatter,
          unparser: unparseDim,
          validator: (v) => !!v,
          default: () => dimParser("0"),
        },
        {
          label: "=",
          prop: "cache.height_prime",
          readOnly: true,
          formatter: dimFormatter,
          unparser: unparseDim,
        },
      ],
    },
    {
      label: "Type",
      prop: "type_id",
      type: "select",
      optionMap: t.reduce((obj, type) => {
        if (group.project_type === "product") {
          obj[type.id] = type.name;
        } else {
          obj[type.id] = type.mark;
        }
        return obj;
      }, {}),
      options: t.map((type) => type.id),
      readOnly: disabled,
    },
    {
      label: "Description",
      prop: "description",
      type: "string",
      readOnly: disabled,
    },
    {
      label: "Quantity",
      prop: "quantity",
      type: "integer",
      parser: parseInteger,
      readOnly: disabled,
      validator: (v) => v >= 0,
      default: () => 1,
    },
    {
      label: displayUnit === "inches" ? "Est. Area (sf)" : "Est. Area (m²)",
      prop: "cache.area",
      readOnly: true,
      formatter: areaFormatter,
    },
    {
      label: "Est. Wt. (lbs)",
      prop: "cache.weight",
      readOnly: true,
      formatter: (w) => (typeof w === "number" ? w.toFixed(0) : ""),
    },
  ]
    .filter((col) => {
      if (sc[col.prop]) return sc[col.prop].visible;
      return true;
    })
    .map((col) => {
      if (sc[col.prop] && sc[col.prop].override) {
        col.label = sc[col.prop].override;
      }

      return col;
    });

  const cols = [
    ...standardCols,
    ...orgColumns.map((c) => {
      return {
        label: c.name,
        prop: `custom_column_data.${c.id}`,
        readOnly: disabled,
        orgColumn: true,
        customColumn: true,
        id: c.id,
      };
    }),
    ...jobColumns.map((c) => {
      return {
        label: c.name,
        prop: `custom_column_data.${c.id}`,
        readOnly: disabled,
        jobColumn: true,
        customColumn: true,
        id: c.id,
        deletable: !disabled,
        renamable: !disabled,
      };
    }),
    ...extraColumns,
  ];

  const order = get(group.data, "column_order") || [];
  return sortArrayBy(cols, order, "prop");
}

type Vertex = [x: number, y: number]

function itemFromVertices(vertices: Array<Vertex>, unit: DimTextUnit = "inches", rotation = 0) {
  const rotated = vertices.map((v) =>
    rotatePoint({ x: v[0], y: v[1] }, rotation),
  );

  for (let i = 0; i < rotated.length; i++) {
    const v = rotated[i];

    // Check for vertices that are very close to other vertices
    if (almostEqual(v.x, 0)) v.x = 0;
    if (almostEqual(v.y, 0)) v.y = 0;
    for (let j = 0; j < i; j++) {
      const u = rotated[j];
      if (almostEqual(v.x, u.x)) {
        v.x = u.x;
      }

      if (almostEqual(v.y, u.y)) {
        v.y = u.y;
      }
    }
  }

  const minX = Math.min(...rotated.map((v) => v.x));
  const minY = Math.min(...rotated.map((v) => v.y));
  const maxX = Math.max(...rotated.map((v) => v.x));
  const maxY = Math.max(...rotated.map((v) => v.y));

  const scaled = rotated.map((v) => {
    const x = new Quantity(v.x - minX, unit).convert("inches");
    const y = new Quantity(v.y - minY, unit).convert("inches");

    return {
      x: x.toNumber(),
      y: y.toNumber(),
    };
  });

  if (cwVertices(scaled)) {
    scaled.reverse();
  }

  // Make 0th vertex the bottom left
  const leftIndices = scaled
    .map((v, index) => index)
    .filter((index) => scaled[index].x === 0);
  const leftYs = leftIndices.map((index) => scaled[index].y);
  const blIndex = leftIndices[leftYs.indexOf(Math.min(...leftYs))];

  // Rotate vertices so that the bottom left is the 0th vertex
  const shifted = scaled.slice(blIndex).concat(scaled.slice(0, blIndex));

  const width = new Dimension(new Quantity(maxX - minX, unit));
  const width_offset = new Dimension(new Quantity(0, unit));
  const height = new Dimension(new Quantity(maxY - minY, unit));
  const height_offset = new Dimension(new Quantity(0, unit));

  const shape = isRectangle({ vertices: [shifted] })
    ? { type: "rect" }
    : {
        type: "free",
        vertices: [shifted],
      };

  return hydrateItem({
    width,
    width_offset,
    height,
    height_offset,
    data: {},
    shape,
    rectangle_offset: {},
    is_imported: true,
  });
}

export type { ItemRecordCore }

export {
  createItem,
  hydrateItem,
  updateItemCache,
  makeColumns,
  itemFromVertices,
}
