import { OffscreenCanvas } from "isomorphs/canvas";

/** @typedef {import('@diagraphics/pdfer/src/writer.js').PDFWriter} PDFWriter */
/** @typedef {import('isomorphs/canvas').BufferedImage} BufferedImage */

/**
 *
 * @param {PDFWriter} ctx
 * @param {Array<object>} commands
 */
function draw(ctx, commands) {
  let start = [0, 0];
  let prev = [0, 0];

  for (const { command, params } of commands) {
    switch (command) {
      case "M":
        {
          const [x, y] = params;
          start = prev = [x, y];
          ctx.moveTo(x, y);
        }
        break;
      case "L":
        {
          const [x, y] = params;
          prev = [x, y];
          ctx.lineTo(x, y);
        }
        break;
      case "A":
        {
          const [x0, y0] = prev;
          const [rx, ry, theta, largeArcFlag, sweepFlag, x1, y1] = params;

          ctx.ellipticalArc(
            x0,
            y0,
            rx,
            ry,
            theta,
            largeArcFlag,
            sweepFlag,
            x1,
            y1,
          );

          prev = [x1, y1];
        }
        break;
      case "Z":
        {
          prev = start;
          ctx.close();
        }
        break;
    }
  }
}

/**
 * @param {PDFWriter} ctx
 * @param {object} el
 */
function path(ctx, el) {
  const stroked = el.stroke && el.stroke !== "transparent";
  const filled = el.fill && el.fill !== "transparent";

  if (stroked || filled) {
    draw(ctx, el.commands);
  }

  if (stroked) {
    ctx.setLineWidth(el.lineWidth);
    ctx.setDrawColor(el.stroke);
    if (el.lineDash) {
      ctx.setLineDashPattern(el.lineDash, 0);
    } else {
      // @ts-ignore
      ctx.setLineDashPattern();
    }
  }

  if (filled) {
    ctx.setFillColor(el.fill);
  }

  if (stroked && filled) {
    ctx.fillStroke();
  } else if (filled) {
    ctx.fill();
  } else if (stroked) {
    ctx.stroke();
  }
}

/**
 *
 * @param {PDFWriter} ctx
 * @param {object} el
 */
function mask(ctx, el) {
  ctx.saveGraphicsState();
  draw(ctx, el.commands);
  ctx.clip("evenodd");
  ctx.discardPath();
}

/**
 *
 * @param {PDFWriter} ctx
 */
function closeMask(ctx) {
  ctx.restoreGraphicsState();
}

/**
 *
 * @param {PDFWriter} ctx
 * @param {object} el
 */
function text(ctx, el) {
  const { x, y } = el;
  const xf = ctx.Matrix(1, 0, 0, -1, x, y);

  ctx.withTransform(xf, (ctx) => {
    ctx
      .setFont(el.font)
      .setTextColor(el.fill)
      .setFontSize(el.fontSize * el.annoScale)
      .text(el.text, 0, 0, {
        angle: -el.rotation,
        align: el.textAlign,
      });
  });
}

/**
 * @param {PDFWriter} ctx
 * @param {object} el
 * @param {BufferedImage} el.image
 * @param {number} el.width
 * @param {number} el.height
 */
// eslint-disable-next-line no-unused-vars
function image(ctx, el) {

  ctx.withTransform(ctx.Matrix(1, 0, 0, -1, el.x, el.y), (ctx) => {
    const { image: { buffer }, width, height } = el;

    ctx.addImage(buffer, "PNG", 0, 0, width, height);
  });
}

/** @type {OffscreenCanvas} */
let canvas = null;

/**
 *
 * @param {PDFWriter} ctx
 * @param {string} text
 * @param {*} styles
 */
function measureText(ctx, text, styles) {
  if (canvas === null) {
    canvas = new OffscreenCanvas(200, 200);
  }

  const cctx = canvas.getContext("2d");

  cctx.font = `${styles.fontWeight} ${styles.fontSize}px ${styles.font}`;
  const metrics = cctx.measureText(text);

  // TODO calculate without adding useless commands to the content stream
  const width = ctx
    .saveGraphicsState()
    .setFontSize(styles.fontSize)
    .setFont(styles.font)
    .getTextWidth(text);

  const k = width / metrics.width;

  let f = 1;

  ctx.restoreGraphicsState();

  return {
    width: f * width,
    actualBoundingBoxAscent: f * k * metrics.actualBoundingBoxAscent,
    actualBoundingBoxDescent: f * k * metrics.actualBoundingBoxDescent,
  };
}

/**
 *
 * @param {PDFWriter} ctx
 * @param {Array<object>} stack
 */
function play(ctx, stack) {
  const ops = { path, mask, closeMask, text, image };
  const flipY = ctx.Matrix(1, 0, 0, -1, 0, 0);

  //console.log('[jspdf-native] CTX =', ctx);

  ctx.withTransform(flipY, (ctx) => {
    for (const el of stack) {
      const op = ops[el.type];
      op(ctx, el);
    }
  });
}

export default { play, measureText };
