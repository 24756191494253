import moo from "moo";
import { exhaust } from "overtype";

import type { Rule, Rules } from "moo";
import type { DimTextOptions } from "./options";


type RuleSpec = Rules[string];
type ArrayOf<T> = T extends Array<any> ? T : never;

const SIGNS = [
  [/[-\u2212]/, "-"],
  [/[+]/, "+"],
] as const;

/* Add units here */
const UNITS = [
  [/['\u2018\u2019\u2032]-?/, "feet"],
  [/["\u201C\u201D\u2033]/, "inches"],
  ["ft", "feet"],
  ["in", "inches"],
  ["mm", "millimeters"],
  ["m", "meters"],
  ["cm", "centimeters"],
] as const;

type ParseableUnit = typeof UNITS[number][1];

const parseableUnits: Set<ParseableUnit> = new Set();

UNITS.forEach((e) => parseableUnits.add(e[1]));

function setNext(rule: RuleSpec, next: string): RuleSpec {
  if (rule instanceof RegExp || typeof rule === "string") {
    return { match: rule, next };
  } else if (Array.isArray(rule)) {
    let res: any = [];
    for (const subrule of rule) {
      res.push(setNext(subrule, next));
    }
    return res as ArrayOf<RuleSpec>;
  } else if (typeof rule === "object") {
    return { ...rule, next };
  } else {
    exhaust(rule);
  }
}

function symbolTable(t: ReadonlyArray<readonly [string | RegExp, string]>): Rule[] {
  return t.map((e) => {
    const [match, val] = e;
    return { match, value: () => val };
  });
}

const sign = symbolTable(SIGNS);
const solidus = /\//;
const radpoint = /[.,]/;
const separator = /[_-]/;
const digits = /\d+/;
const symbol = symbolTable(UNITS);
const ws = /[ \t]+/; // Whitespace not otherwise captured

/*
 * Pattern matching the blank in a mixed fraction like
 *
 * 1 1/2
 *
 * Formally, a run of whitespace that is preceded by a digit
 * and followed by any number of digits,
 * followed by any number of spaces,
 * followed by a slash.
 */
const impliedSeparator = {
  match: /\s+(?=\d+\s*[/])/,
};

/*
 * A pattern matching a run of blanks between digits or a naked radix point
 */
const impliedUnit = {
  match: /\s+(?=[\d\.,])/,
  value: () => "feet", // Current interpretation: implicit unit is always feet.
};

function lexer(
  options: Partial<DimTextOptions> = {
    // TODO: apply defaults consistently with main.ts
    defaultUnit: "inches",
  }
) {
  let baseLexer = {
    main: {
      sign,
      solidus,
      radpoint,
      separator,
      digits: setNext(digits, "afterDigits"),
      symbol,
      ws,
    },
    afterDigits: {
      solidus: setNext(solidus, "main"),
      radpoint: setNext(radpoint, "main"),
      separator: [
        setNext(separator, "main"),
        setNext(impliedSeparator, "main"),
      ],
      symbol: [
        ...(setNext(symbol, "main") as Rule[]),
        setNext(impliedUnit, "main"),
      ],
      ws: setNext(ws, "main"),
    },
  };

  /* if (options.allowImplicitMixedFractions) {
        baseLexer.separator.push(impliedSeparator)
    }

    if (options.allowImplicitUnits) {
        baseLexer.symbol.push(impliedUnit);
    }*/

  // @ts-ignore
  return moo.states(baseLexer);
}

export default lexer;
export { parseableUnits };

export type { ParseableUnit }
