import svgArcToCenterParam from "../utils/svgarc-to-center.js";

const pts = {
  M: (params) => [params[0], params[1]],
  L: (params) => [params[0], params[1]],
  A: (params) => [params[5], params[6]],
  Z: (params, st) => [...st],
};

const draw = {
  M: (ctx, cmd) => ctx.moveTo(cmd.params[0], cmd.params[1]),
  L: (ctx, cmd) => ctx.lineTo(cmd.params[0], cmd.params[1]),
  A: (ctx, cmd, prev) => {
    try {
      const { cx, cy, sa, ea, cw } = svgArcToCenterParam(
        prev[0],
        prev[1],
        ...cmd.params
      );

      ctx.ellipse(
        cx,
        cy,
        cmd.params[0], // x radius
        cmd.params[1], // y radius
        cmd.params[2], // rotation
        sa,
        ea,
        !cw
      );
    } catch (_) {
      ctx.lineTo(cmd.params[5], cmd.params[6]);
    }
  },
  Z: (ctx) => ctx.closePath(),
};

const play = {
  path(ctx, el, scale) {
    /*
      A simpler approach than the following (but one that requires use
      of the Path2D constructor, which is not available in node-canvas):

      const d = el.commands
        .map((c) => {
          return `${c.command} ${c.params.join(" ")}`;
        })
        .join(" ");

      const p = new Path2D(d);
    */

    let st = [0, 0];
    let prev = [0, 0];
    ctx.beginPath();
    el.commands.forEach((cmd) => {
      if (cmd.command === "M") {
        st = [...cmd.params];
      }

      draw[cmd.command](ctx, cmd, prev);
      prev = pts[cmd.command](cmd.params, st);
    });

    if (el.fill) {
      ctx.fillStyle = el.fill;
      ctx.fill();
    }

    if (el.stroke) {
      ctx.strokeStyle = el.stroke;
      ctx.lineWidth = el.lineWidth;
      if (el.lineDash) {
        ctx.setLineDash(el.lineDash);
      } else {
        ctx.setLineDash([]);
      }
      ctx.stroke();
    }
  },

  mask(ctx, el) {
    let st = [0, 0];
    let prev = [0, 0];
    ctx.save();
    ctx.beginPath();
    el.commands.forEach((cmd) => {
      if (cmd.command === "M") {
        st = [...cmd.params];
      }

      draw[cmd.command](ctx, cmd, prev);
      prev = pts[cmd.command](cmd.params, st);
    });

    ctx.clip("evenodd");
  },

  closeMask(ctx, el) {
    ctx.restore();
  },

  text(ctx, el) {
    // check for annotation scales that are too small; in that case, we
    // need to render the text at a larger size, and then scale it down.
    let scale = 1;
    if (el.annoScale < 1) {
      scale = 100;
    }

    const pixelSize = el.fontSize * el.annoScale * scale;

    const fontString = `${el.fontWeight} ${pixelSize}px ${el.font}`;

    // console.log(
    //   "[drawing/canvas] fontSize=%f annoScale=%f scale=%f fontString=%s transform=%o",
    //   el.fontSize,
    //   el.annoScale,
    //   scale,
    //   fontString,
    //   ctx.getTransform().scaleX,
    // );

    ctx.font = fontString;
    ctx.fillStyle = el.fill;
    ctx.textAlign = el.textAlign;
    ctx.save();
    ctx.translate(el.x, el.y);
    ctx.scale(1 / scale, -1 / scale);
    ctx.rotate(-el.rotation);
    ctx.textAlign = el.textAlign;
    ctx.fillText(el.text, 0, 0);
    ctx.restore();
  },

  image(ctx, el) {
    ctx.save();
    ctx.translate(el.x, el.y);
    ctx.scale(1, -1);
    ctx.globalAlpha = el.opacity;
    if (el.width || el.height) {
      ctx.drawImage(el.image, 0, 0, el.width, el.height);
    } else {
      ctx.drawImage(el.image, 0, 0);
    }
    ctx.restore();
  },
};

export default {
  measureText(ctx, text, styles) {
    ctx.font = `${styles.fontWeight} ${styles.fontSize}px ${styles.font}`;
    const mt = ctx.measureText(text);
    return {
      width: mt.width,
      actualBoundingBoxAscent: mt.actualBoundingBoxAscent,
      actualBoundingBoxDescent: mt.actualBoundingBoxDescent,
    };
  },

  play(ctx, stack) {
    ctx.scale(1, -1);
    stack.forEach((el) => play[el.type](ctx, el));
    ctx.scale(1, -1);
  },
};
