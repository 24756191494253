const ESCAPED_HTML_CHARS = {
  "&nbsp;": "\x20",
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
};
const regEscapedChars = new RegExp(
  Object.keys(ESCAPED_HTML_CHARS)
    .map((key) => `(${key})`)
    .join("|"),
  "gi",
);

function isHTMLTable(element) {
  return ((element && element.nodeName) || "") === "TABLE";
}

export default function parseHtmlTable(element) {
  const fragment = document.createDocumentFragment();
  const el = document.createElement("div");
  fragment.appendChild(el);

  if (typeof element === "string") {
    const escaped = element.replace(
      /<td\b[^>]*?>([\s\S]*?)<\/\s*td>/g,
      (cellFragment) => {
        const openingTag = cellFragment.match(/<td\b[^>]*?>/g)[0];
        const paragraphRegexp = /<p.*?>/g;
        const cellValue = cellFragment
          .substring(openingTag.length, cellFragment.lastIndexOf("<"))
          .trim()
          .replaceAll(/\n\s+/g, " ") // HTML tags may be split using multiple new lines and whitespaces
          .replaceAll(paragraphRegexp, "\n") // Only paragraphs should split text using new line characters
          .replace("\n", "") // First paragraph shouldn't start with new line characters
          .replaceAll(/<\/(.*)>\s+$/gm, "</$1>") // HTML tags may end with whitespace.
          .replace(/(<(?!br)([^>]+)>)/gi, "") // Removing HTML tags
          .replaceAll(/^&nbsp;$/gm, ""); // Removing single &nbsp; characters separating new lines
        const closingTag = "</td>";

        return `${openingTag}${cellValue}${closingTag}`;
      },
    );

    el.insertAdjacentHTML("afterbegin", `${escaped}`);
    element = el.querySelector("table");
  }

  if (!element || !isHTMLTable(element)) {
    return;
  }

  const generator = el.querySelector('meta[name$="enerator"]');
  const hasRowHeaders = element.querySelector("tbody th") !== null;
  const trElement = element.querySelector("tr");
  const countCols = !trElement
    ? 0
    : Array.from(trElement.cells).reduce(
        (cols, cell) => cols + cell.colSpan,
        0,
      ) - (hasRowHeaders ? 1 : 0);
  const fixedRowsBottom =
    (element.tFoot && Array.from(element.tFoot.rows)) || [];
  const fixedRowsTop = [];
  let countRows = 0;

  const dataRows = [
    ...fixedRowsTop,
    ...Array.from(element.tBodies).reduce((sections, section) => {
      sections.push(...Array.from(section.rows));

      return sections;
    }, []),
    ...fixedRowsBottom,
  ];

  countRows = dataRows.length;

  const dataArr = new Array(countRows);

  for (let r = 0; r < countRows; r++) {
    dataArr[r] = new Array(countCols);
  }

  for (let row = 0; row < countRows; row++) {
    const tr = dataRows[row];
    const cells = Array.from(tr.cells);
    const cellsLen = cells.length;

    for (let cellId = 0; cellId < cellsLen; cellId++) {
      const cell = cells[cellId];
      const { innerHTML, rowSpan: rowspan, colSpan: colspan } = cell;
      const col = dataArr[row].findIndex((value) => value === undefined);

      if (rowspan > 1 || colspan > 1) {
        for (let rstart = row; rstart < row + rowspan; rstart++) {
          if (rstart < countRows) {
            for (let cstart = col; cstart < col + colspan; cstart++) {
              dataArr[rstart][cstart] = null;
            }
          }
        }
      }

      let cellValue = "";

      if (generator && /excel/gi.test(generator.content)) {
        cellValue = innerHTML
          .replace(/[\r\n][\x20]{0,2}/g, "\x20")
          .replace(/<br(\s*|\/)>[\r\n]?[\x20]{0,3}/gim, "\r\n");
      } else {
        cellValue = innerHTML.replace(/<br(\s*|\/)>[\r\n]?/gim, "\r\n");
      }

      dataArr[row][col] = cellValue.replace(
        regEscapedChars,
        (match) => ESCAPED_HTML_CHARS[match],
      );
    }
  }

  if (dataArr.length) {
    return dataArr;
  }
  return [];
}
