/**
 * @typedef {import('pdfkit')} PDFDocument
 * @typedef {import('pdfkit/js/mixins/fonts')} PDFKitFont
 * @typedef {import('fontkit').Font} Font
 */

/* Workarounds for React-PDF
   Assumes a patch has been applied to
   @react-pdf/render/lib/primitives/renderCanvas.js
   to expose the PDFKit context as _ctx */

function widthOfString(ctx, text) {
  if (typeof ctx.widthOfString === "function") {
    return ctx.widthOfString(text);
  } else {
    // The React-PDF fork of PDFKit has widthOfString
    // console.log("Expsed context: %o", ctx._ctx);
    return ctx._ctx.widthOfString(text);
  }
}

function heightOfString(ctx, text, options) {
  if (typeof ctx.heightOfString === "function") {
    return ctx.heightOfString(text, options);
  } else {
    /* The React-PDF fork of PDFKit does not have heightOfString
    The function below is copied from upstream */
    /** @type {PDFDocument} */
    const doc = ctx._ctx;
    const { x, y } = doc;

    // @ts-ignore
    options = doc._initOptions(options);
    options.height = Infinity; // don't break pages

    // @ts-ignore
    const lineGap = options.lineGap || doc._lineGap || 0;
    // @ts-ignore
    doc._text(text, doc.x, doc.y, options, () => {
      return (doc.y += doc.currentLineHeight(true) + lineGap);
    });

    const height = doc.y - y;
    doc.x = x;
    doc.y = y;

    return height;
  }
}

const play = {
  path(ctx, el) {
    const d = el.commands
      .map((c) => {
        return `${c.command} ${c.params.join(" ")}`;
      })
      .join(" ");

    if (el.fill && el.stroke) {
      ctx.lineWidth(el.lineWidth);
      ctx.path(d).fillAndStroke(el.fill, el.stroke, el.fillRule);
    } else if (el.fill) {
      ctx.path(d).fill(el.fill);
    } else if (el.stroke) {
      ctx.lineWidth(el.lineWidth);
      ctx.path(d).stroke(el.stroke);
    }
  },

  mask(ctx, el) {
    ctx.save();
    const d = el.commands
      .map((c) => {
        return `${c.command} ${c.params.join(" ")}`;
      })
      .join(" ");

    ctx.path(d).clip();
  },

  closeMask(ctx, el) {
    ctx.restore();
  },

  /**
   * @param {PDFDocument} ctx
   * @param {*} el
   */
  text(ctx, el) {
    ctx
      .font(el.font)
      .fillColor(el.fill)
      .fontSize(el.fontSize * el.annoScale);

    const tw = widthOfString(ctx, el.text.toString());
    const th = heightOfString(ctx, el.text.toString());
    const x = el.textAlign === "center" ? el.x - tw / 2 : el.x;
    // TODO: make vertical shift more accurate (it is fudged here)
    const y = el.y + th * 0.5;

    ctx
      .save()
      .translate(x, y)
      .scale(1, -1)
      .rotate(-el.rotation)
      .text(el.text, 0, 0)
      .restore();
  },

  image(ctx, el) {
    ctx.image(el.src, el.x, el.y, { width: el.width, height: el.height });
  },
};

export default {
  measureText(ctx, text, styles) {
    ctx.fontSize(styles.fontSize);
    ctx.font(styles.font);
    const width = widthOfString(ctx, text);
    const height = heightOfString(ctx, text);
    const actualBoundingBoxAscent = height * (3 / 4);
    const actualBoundingBoxDescent = height * (1 / 4);
    return { width, actualBoundingBoxAscent, actualBoundingBoxDescent };
  },

  play(ctx, stack) {
    ctx.scale(1, -1);
    stack.forEach((el) => play[el.type](ctx, el));
    ctx.scale(1, -1);
  },
};
