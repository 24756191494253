function createJob(orgid: string, props = {}) {
    return {
      id: crypto.randomUUID(),
      name: "New Job",
      location: "",
      organization_id: orgid,
      data: {},
      ...props,
    };
  }

  export { createJob }
