// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
// Bypasses TS6133. Allow declared but unused functions.
// @ts-ignore
function id(d: any[]): any { return d[0]; }
declare var radpoint: any;
declare var digits: any;
declare var sign: any;
declare var ws: any;
declare var separator: any;
declare var solidus: any;
declare var symbol: any;

	import { Dimension, DecimalFraction, CommonFraction, MixedFraction, Quantity } from '../src/ast';
	import lexer from '../src/lexer';

	const deflexer = lexer();


	const nil = (d) => null;
	const second = (d) => d[1];
	const comp = (f) => (d) => f(f(d));
	const sep = (fn) => ([a,,b]) => fn([a,b]);

	const construct = (Ctor) => ([a, b]) => new Ctor(a, b);

	const pp = (fn) => (d: any[], loc?: number, reject?: {}): any => fn(d);

	const dimension = pp((d) => {
    const [s, p, q] = d;

    if (s === null) { // Unsigned
      if (p === null) {
        return new Dimension(q);
      }
      if (p !== null) {
        return new Dimension(p, q);
      }
    } else { // Signed
      if (p === null) {
        return new Dimension(s, q);
      }
      if (p !== null) {
        return new Dimension(s, p, q);
      }
    }

    throw new TypeError("Unexpected arguments to dimension");
  });

	const quant =   pp(construct(Quantity));
	const mixfrac = pp(construct(MixedFraction));
	const ratfrac = pp(sep(construct(CommonFraction)));
	const decfrac = pp(([,digits]) => new DecimalFraction(digits.value));

	const unit = pp(([d]) => d.value);
	const int = pp(([d]) => parseInt(d.value));
  const sgn = pp(([d]) => d.value === "-" ? -1 : 1);

interface NearleyToken {
  value: any;
  [key: string]: any;
};

interface NearleyLexer {
  reset: (chunk: string, info: any) => void;
  next: () => NearleyToken | undefined;
  save: () => any;
  formatError: (token: never) => string;
  has: (tokenType: string) => boolean;
};

interface NearleyRule {
  name: string;
  symbols: NearleySymbol[];
  postprocess?: (d: any[], loc?: number, reject?: {}) => any;
};

type NearleySymbol = string | { literal: any } | { test: (token: any) => boolean };

interface Grammar {
  Lexer: NearleyLexer | undefined;
  ParserRules: NearleyRule[];
  ParserStart: string;
};

const grammar: Grammar = {
  Lexer: deflexer,
  ParserRules: [
    {"name": "dimension$ebnf$1", "symbols": ["sign"], "postprocess": id},
    {"name": "dimension$ebnf$1", "symbols": [], "postprocess": () => null},
    {"name": "dimension$ebnf$2", "symbols": ["intQuantity"], "postprocess": id},
    {"name": "dimension$ebnf$2", "symbols": [], "postprocess": () => null},
    {"name": "dimension", "symbols": ["dimension$ebnf$1", "dimension$ebnf$2", "anyQuantity"], "postprocess": dimension},
    {"name": "intQuantity", "symbols": ["intValue", "unit"], "postprocess": quant},
    {"name": "anyQuantity", "symbols": ["anyValue", "unit?"], "postprocess": quant},
    {"name": "anyValue$subexpression$1", "symbols": ["intValue"]},
    {"name": "anyValue$subexpression$1", "symbols": ["mixfrac"]},
    {"name": "anyValue$subexpression$1", "symbols": ["ratfrac"]},
    {"name": "anyValue$subexpression$1", "symbols": ["decfrac"]},
    {"name": "anyValue", "symbols": ["anyValue$subexpression$1"], "postprocess": pp(comp(id))},
    {"name": "intValue", "symbols": ["int"], "postprocess": id},
    {"name": "mixfrac", "symbols": ["int", "mixsep", "ratfrac"], "postprocess": pp(sep(mixfrac))},
    {"name": "mixfrac", "symbols": ["int", "decfrac"], "postprocess": mixfrac},
    {"name": "ratfrac", "symbols": ["int", "solidus", "int"], "postprocess": ratfrac},
    {"name": "decfrac", "symbols": [(deflexer.has("radpoint") ? {type: "radpoint"} : radpoint), (deflexer.has("digits") ? {type: "digits"} : digits)], "postprocess": decfrac},
    {"name": "sign$macrocall$2", "symbols": [(deflexer.has("sign") ? {type: "sign"} : sign)]},
    {"name": "sign$macrocall$1$ebnf$1", "symbols": []},
    {"name": "sign$macrocall$1$ebnf$1", "symbols": ["sign$macrocall$1$ebnf$1", (deflexer.has("ws") ? {type: "ws"} : ws)], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "sign$macrocall$1$ebnf$2", "symbols": []},
    {"name": "sign$macrocall$1$ebnf$2", "symbols": ["sign$macrocall$1$ebnf$2", (deflexer.has("ws") ? {type: "ws"} : ws)], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "sign$macrocall$1", "symbols": ["sign$macrocall$1$ebnf$1", "sign$macrocall$2", "sign$macrocall$1$ebnf$2"], "postprocess": (d) => d[1][0]},
    {"name": "sign", "symbols": ["sign$macrocall$1"], "postprocess": sgn},
    {"name": "int", "symbols": [(deflexer.has("digits") ? {type: "digits"} : digits)], "postprocess": int},
    {"name": "mixsep", "symbols": [(deflexer.has("separator") ? {type: "separator"} : separator)], "postprocess": nil},
    {"name": "solidus$macrocall$2", "symbols": [(deflexer.has("solidus") ? {type: "solidus"} : solidus)]},
    {"name": "solidus$macrocall$1$ebnf$1", "symbols": []},
    {"name": "solidus$macrocall$1$ebnf$1", "symbols": ["solidus$macrocall$1$ebnf$1", (deflexer.has("ws") ? {type: "ws"} : ws)], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "solidus$macrocall$1$ebnf$2", "symbols": []},
    {"name": "solidus$macrocall$1$ebnf$2", "symbols": ["solidus$macrocall$1$ebnf$2", (deflexer.has("ws") ? {type: "ws"} : ws)], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "solidus$macrocall$1", "symbols": ["solidus$macrocall$1$ebnf$1", "solidus$macrocall$2", "solidus$macrocall$1$ebnf$2"], "postprocess": (d) => d[1][0]},
    {"name": "solidus", "symbols": ["solidus$macrocall$1"], "postprocess": nil},
    {"name": "unit?", "symbols": ["unit"], "postprocess": id},
    {"name": "unit?", "symbols": [], "postprocess": () => 'default'},
    {"name": "unit$macrocall$2", "symbols": [(deflexer.has("symbol") ? {type: "symbol"} : symbol)]},
    {"name": "unit$macrocall$1$ebnf$1", "symbols": []},
    {"name": "unit$macrocall$1$ebnf$1", "symbols": ["unit$macrocall$1$ebnf$1", (deflexer.has("ws") ? {type: "ws"} : ws)], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "unit$macrocall$1$ebnf$2", "symbols": []},
    {"name": "unit$macrocall$1$ebnf$2", "symbols": ["unit$macrocall$1$ebnf$2", (deflexer.has("ws") ? {type: "ws"} : ws)], "postprocess": (d) => d[0].concat([d[1]])},
    {"name": "unit$macrocall$1", "symbols": ["unit$macrocall$1$ebnf$1", "unit$macrocall$2", "unit$macrocall$1$ebnf$2"], "postprocess": (d) => d[1][0]},
    {"name": "unit", "symbols": ["unit$macrocall$1"], "postprocess": unit}
  ],
  ParserStart: "dimension",
};

export default grammar;
