import get from "lodash/get.js";
import cloneDeep from "lodash/cloneDeep.js";
import isArray from "lodash/isArray.js";
import mapValues from "lodash/mapValues.js";
import { map, partition, zipObject } from "overline/iterable";
import { orderedList } from "@local/extensions/collections/sortable-list.js";
import getTotalThickness from "@local/extensions/formatters/get-total-thickness";
import { makeColumns } from "../entities/item.js";
import { makeCollectionColumns } from "../entities/collection.js";
import { edgeTreatmentColumn } from "@local/extensions/formatters/edge-treatment.js";
import { shapeIdentColumn } from "@local/extensions/formatters/shape-ident.js";

function prepareColumns(
  group,
  types,
  columnMaker = makeColumns,
  customColumnsKey = "custom_columns",
  extraColumns = [],
) {
  const orgColumns = group.organization.data[customColumnsKey];
  const jobColumns = group.data[customColumnsKey];
  const standardColumns = group.organization.data.standard_columns || {};

  return columnMaker(
    group,
    types,
    false,
    orgColumns ? orderedList(orgColumns) : [],
    jobColumns ? orderedList(jobColumns) : [],
    standardColumns || {},
    extraColumns,
  );
}

function* itemValues(item, columns) {
  for (const col of columns) {
    const val = get(item, col.prop);
    if (col.prop === "type_id") {
      yield col.optionMap[val];
      yield val;
    } else if (col.type === "select") {
      yield col.optionMap[val];
    } else if (col.formatter) {
      yield col.formatter(val, item);
    } else {
      yield val;
    }
  }
}

function* columnProps(columns) {
  for (const col of columns) {
    if (col.prop === "type_id") {
      yield "type_id";
      yield "type_uuid";
    } else {
      yield col.prop;
    }
  }
}

function itemDataMap(group, items, columns, productData, productListId) {
  if (group.project_type === "product" && productListId) {
    return map(items, (item) => {
      const type = group.types[item.type_id];
      const priceEntry = productData.priceEntries[type?.product_id]?.[productListId];
      let price_each;
      let price_total;
      // TODO: support alternate pricing units
      if (priceEntry && priceEntry.unit === "item") {
        price_each = priceEntry.unit_price;
        price_total = priceEntry.unit_price * item.quantity;
      }

      return {
        id: item.id,
        ...zipObject(columnProps(columns), itemValues(item, columns)),
        price_each,
        price_total,
      }
    });
  } else {
    return map(items, (item) => ({
      id: item.id,
      ...zipObject(columnProps(columns), itemValues(item, columns)),
    }));
  }
}

function rowsAsMaps(items, columns) {
  return map(items, (item) => ({
    id: item.id,
    ...zipObject(columnProps(columns), itemValues(item, columns)),
  }));
}

/**
 *
 * @param {Group} group
 * @param {Array<Type>} types
 * @param {"array" | "map" } [rowFormat]
 * @returns
 */
function prepareData(group, types, productData, productListId = null) {
  const obj = cloneDeep(group);

  const itemCols = prepareColumns(
    group,
    types,
    makeColumns,
    "custom_columns",
    [shapeIdentColumn, edgeTreatmentColumn(productData.edgeTreatments)].filter(
      (c) => c.displayFilter([...obj.items]),
    ),
  );

  const openingCols = prepareColumns(
    group,
    types,
    makeCollectionColumns,
    "custom_collection_columns",
  );

  const [openings, items] = partition(obj.items, (item) => item.is_collection);

  const itemData = itemDataMap(group, items, itemCols, productData, productListId);

  const openingData = rowsAsMaps(openings, itemCols);

  const typeCols = [
    { label: "Mark", prop: "mark" },
    { label: "Name", prop: "name" },
    // { label: "Seller Ref.", prop: "seller_reference" },
    // { label: "Buyer Ref.", prop: "buyer_reference" },
    { label: "Layers", prop: "layers" },
    { label: "Computed Thickness", prop: "total_thickness" },
    { label: "Desired Thickness", prop: "desired_thickness" },
    { label: "Area", prop: "cache.area" },
    { label: "Quantity", prop: "cache.quantity" },
  ];

  const typeSettings = {
    display_unit: group.data.settings.display_unit,
    mm_precision: group.data.settings.mm_precision,
    decimal_precision: 3,
    fractional_precision: 5,
    dimension_format: group.data.settings.dimension_format,
  };

  const typeIDs = types.map((type) => type.id);
  const typeData = types.map((type) => {
    return typeCols.map((p) => {
      if (p.prop === "total_thickness") {
        return getTotalThickness(type, typeSettings);
      }

      if (p.prop === "desired_thickness") {
        return type.desired_thickness?.toString();
      }

      if (p.prop === "layers") {
        // @ts-ignore
        return type.data.layers?.length ?? 0;
      }

      if (p.prop === "cache.quantity") {
        return "0";
      }

      if (p.prop === "cache.area") {
        return "0";
      }

      const val = get(type, p.prop);

      return val;
    });
  });

  // const resolvedItemData = [...itemData];

  // log.trace("Item Data", resolvedItemData);

  return {
    itemCols,
    itemData: [...itemData],
    openingCols,
    openingData: [...openingData],
    typeIDs,
    typeCols,
    typeData,
    jobData: [
      ["Name", "Location"],
      // @ts-ignore
      [group.job.name, group.job.location],
    ],
  };
}

function mapRow(row, fn) {
  if (isArray(row)) {
    return row.map(fn);
  } else {
    return mapValues(row, fn);
  }
}

/**
 *
 * @param {ReturnType<typeof prepareData>} data
 * @param {Function} translate
 * @returns
 */
function stringifyData(data, translate) {
  const {
    itemCols,
    itemData,
    openingCols,
    openingData,
    typeCols,
    typeData,
    jobData,
  } = data;

  return {
    itemHeaders: itemCols.map((c) => c.label),
    /* index to be used in evil hotfix */
    typeColumnIndex: itemCols.findIndex((c) => c.prop === "type_id"),
    itemData: itemData.map((row) => mapRow(row, translate)),
    openingHeaders: openingCols.map((c) => c.label),
    openingData: openingData.map((row) => mapRow(row, translate)),
    typeHeaders: typeCols.map((c) => c.label),
    typeData: typeData.map((row) => mapRow(row, translate)),
    jobData,
  };
}

export { prepareData, stringifyData };
