<script>
  import { createEventDispatcher } from "svelte";
  import Portal from "./Portal.svelte";
  import XIcon from "#icons/x.svg";

  export let isOpen = false;
  export let closeable = false;
  export let fullframe = false;
  export let closeOnOutclick = false;
  export let buttons = [];
  export let width = "24rem";
  export let height = null;

  const dispatch = createEventDispatcher();

  $: containerStyles = `width:${width};` + (height ? `height:${height};` : "");

  export const open = () => {
    isOpen = true;
    dispatch("open");
  };

  export const close = () => {
    isOpen = false;
    dispatch("close");
  };

  async function handleClick(button) {
    if (button.type === "confirm") {
      await dispatch("confirm", button);
    }

    if (button.closeOnClick || button.closeOnClick === undefined) {
      close();
    }
  }

  function outclick() {
    if (closeOnOutclick) close();
  }
</script>

{#if isOpen}
  <Portal>
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div
      class="fixed modal-container top-0 right-0 bottom-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-900/25"
      on:click|stopPropagation={outclick}
      on:mousedown|stopPropagation>
      <div
        class="bg-white rounded shadow-lg text-sm max-h-full relative flex flex-col overflow-hidden"
        style={containerStyles}
        on:click|stopPropagation>
        <div class="w-full px-4 py-2 border-b text-gray-500 flex-none">
          {#if closeable}
            <div class="flex justify-between items-center">
              <div>
                <slot name="title" />
              </div>
              <div class="p-1 rounded hover:bg-gray-100 cursor-pointer" on:click={close}>
                <XIcon />
              </div>
            </div>
          {:else}
            <slot name="title" />
          {/if}
        </div>

        <div class="w-full text-left grow overflow-hidden flex flex-col" class:p-4={!fullframe}>
          <slot name="content" />
        </div>

        {#if buttons.length > 0}
          <div class="w-full px-4 mb-4 flex space-x-2 items-center justify-end flex-none">
            {#each buttons as button}
              <button
                class="btn"
                class:btn-danger-minimal={button.style === "danger"}
                class:btn-primary={button.style === "primary"}
                class:cursor-not-allowed={button.disabled}
                class:opacity-50={button.disabled}
                disabled={button.disabled}
                on:click={() => handleClick(button)}>{button.label}</button>
            {/each}
          </div>
        {/if}

        {#if $$slots.footer}
          <div class="w-full border-t py-2 px-4">
            <slot name="footer" />
          </div>
        {/if}
      </div>
    </div>
  </Portal>
{/if}

<style>
  .modal-container {
    z-index: 200;
  }
</style>
