import convert from "convert";
import { isClose } from "is-close";
import { gcd, modf, num2frac } from "./math";
import { exhaust, insist } from "overtype";
import { formatFraction } from "./frac";
import { parseableUnits } from "./lexer";
import { LinearDisplayFormat, defaultDimTextOptions } from "./options";

import type { LinearDisplayPrecision, LinearDisplayOptions } from "./options";
import type { ParseableUnit } from "./lexer";

const defaultDisplayOptions = defaultDimTextOptions.displayOptions;

type Unit = ParseableUnit

function isUnit(x: any): x is Unit {
  return parseableUnits.has(x);
}

/** Type to indicate intent */
type Integer = number;

interface PlainDecimalFraction {
  sig: Integer;
  exp: Integer;
}

class DecimalFraction implements PlainDecimalFraction {
  sig: Integer;
  exp: Integer;

  constructor(num: number);
  constructor(digits: string);
  constructor(obj: PlainDecimalFraction);
  constructor(sig: Integer, exp: Integer);
  constructor(
    ...args:
      | []
      | [number]
      | [string]
      | [PlainDecimalFraction]
      | [Integer, Integer]
  ) {
    let sig, exp;

    switch (args.length) {
      case 1:
        const [arg] = args;
        switch (typeof arg) {
          case "string":
            sig = parseInt(arg);
            exp = -arg.length;
            break;
          case "number":
            /* This could get hairy due to float precision issues */
            throw new Error("Not Implemented");
            break;
          default:
            sig = arg.sig;
            exp = arg.exp;
        }
        break;
      case 2:
        [sig, exp] = args;
        break;
      default:
        throw new TypeError();
    }

    this.sig = sig;
    this.exp = exp;
  }

  isDecimal(): this is DecimalFraction {
    return true;
  }

  isCommon(): this is CommonFraction {
    return false;
  }

  toNumber(): number {
    return this.sig * 10 ** this.exp;
  }

  valueOf(): number {
    return this.toNumber();
  }

  toFixed(digits: number, leadingZero = true): string {
    const fixed = this.toNumber().toFixed(digits);
    return leadingZero ? fixed : fixed.slice(1);
  }

  toString(leadingZero = true): string {
    return this.toFixed(-this.exp, leadingZero);
  }

  toJSON() {
    return {
      sig: this.sig,
      exp: this.exp,
    };
  }

  toCommon(precision: number): CommonFraction {
    if (!Number.isInteger(precision) || precision < 0) {
      throw TypeError(
        "Precision must be an integer greater than or equal to zero"
      );
    }
    const sig = this.sig;
    const exp = this.exp;

    const den = 2 ** precision;
    const num = Math.round(10 ** exp * sig * den);

    return new CommonFraction(num, den).toReduced();
  }

  clone(): DecimalFraction {
    return new DecimalFraction(this);
  }
}

interface PlainCommonFraction {
  num: Integer;
  den: Integer;
}

class CommonFraction implements PlainCommonFraction {
  num: Integer;
  den: Integer;

  //TODO: allow rounding to precision at construction?
  constructor(obj: PlainCommonFraction);
  constructor(num: Integer, den: Integer);
  constructor(...args: [] | [PlainCommonFraction] | [Integer, Integer]) {
    let num, den;

    switch (args.length) {
      case 1:
        [{ num, den }] = args;
        break;
      case 2:
        [num, den] = args;
        break;
      default:
        throw new TypeError();
    }

    this.num = num;
    this.den = den;
  }

  isDecimal(): this is DecimalFraction {
    return false;
  }

  isCommon(): this is CommonFraction {
    return true;
  }

  toReduced(): CommonFraction {
    const { num, den } = this;
    const d = gcd(num, den);
    return new CommonFraction(num / d, den / d);
  }

  toCommon(precision: number): CommonFraction {
    const newDen = 2 ** precision;
    const { num, den } = this;
    if (den === newDen) {
      return this.clone();
    } else {
      const newNum = Math.round((num * newDen) / den);
      return new CommonFraction(newNum, newDen).toReduced();
    }
  }

  toNumber(): number {
    return this.num / this.den;
  }

  valueOf(): number {
    return this.toNumber();
  }

  toString(/* leadingZero = false */): string {
    return `${this.num}/${this.den}`;
  }

  toJSON() {
    return {
      num: this.num,
      den: this.den,
    };
  }

  clone(): CommonFraction {
    // TODO make this consistent with otheres
    return new CommonFraction(this.num, this.den);
  }
}

type Fraction = DecimalFraction | CommonFraction;
type PlainFraction = PlainDecimalFraction | PlainCommonFraction;

interface PlainMixedFraction {
  ipart: Integer;
  fpart: PlainFraction;
}

class MixedFraction implements PlainMixedFraction {
  ipart: Integer;
  fpart: Fraction;

  constructor(
    ...args: [obj: PlainMixedFraction] | [ipart: Integer, fpart: Fraction]
  ) {
    switch (args.length) {
      case 1: {
        const [{ ipart, fpart }] = args;
        this.ipart = ipart;
        if ("num" in fpart) {
          this.fpart = new CommonFraction(fpart);
        } else if ("sig" in fpart) {
          this.fpart = new DecimalFraction(fpart);
        } else {
          exhaust(fpart);
        }
        break;
      }
      case 2: {
        const [ipart, fpart] = args;
        this.ipart = ipart;
        this.fpart = fpart;
        break;
      }
      default:
        exhaust(args);
    }
  }

  isDecimal(): boolean {
    return this.fpart.isDecimal();
  }

  isCommon(): boolean {
    return this.fpart.isCommon();
  }

  toCommon(precision: number): MixedFraction {
    return new MixedFraction(this.ipart, this.fpart.toCommon(precision));
  }

  toNumber(): number {
    return this.ipart + this.fpart.toNumber();
  }

  valueOf(): number {
    return this.toNumber();
  }

  toString(): string {
    let fpart;
    if (this.isDecimal()) {
      fpart = this.fpart;
      return `${this.ipart}${fpart.toString(false)}`;
    } else {
      fpart = this.fpart;
      return `${this.ipart}-${this.fpart}`;
    }
  }

  toJSON() {
    return {
      ipart: this.ipart,
      fpart: this.fpart,
    };
  }

  clone(): MixedFraction {
    return new MixedFraction(this.ipart, this.fpart.clone());
  }
}

function unitToSymbol(
  unit: Unit,
  options: Pick<LinearDisplayOptions, "unitSpacing" | "unitSymbols">
): string {
  let feet, inches;

  switch (options.unitSymbols) {
    case "ascii":
      feet = `'`;
      inches = `"`;
      break;
    case "unicode":
      feet = "\u2032";
      inches = "\u2033";
      break;
    case "abbrev":
    default:
      feet = `ft`;
      inches = `in`;
      break;
  }

  let symbol: string = {
    feet,
    inches,
    millimeters: "mm",
    centimeters: "cm",
    meters: "m",
  }[unit];

  switch (options.unitSpacing) {
    case "before":
      return ` ${symbol}`;
    case "after":
      return `${symbol} `;
    case "both":
    case true:
      return ` ${symbol} `;
    case false:
    default:
      return symbol;
  }
}

type Value = Integer | MixedFraction | DecimalFraction | CommonFraction;
type PlainValue = Integer | PlainMixedFraction | PlainDecimalFraction | PlainCommonFraction;

function isQuantity(obj: any): obj is Quantity {
  return (
    obj !== null && typeof obj === "object" && "value" in obj && "unit" in obj
  );
}


interface PlainQuantity {
  value: PlainValue;
  unit: Unit;
}

class Quantity {
  value: Value;
  unit: Unit;

  constructor(quant: PlainQuantity);
  constructor(value: Value, unit: Unit);
  constructor(...args: [PlainQuantity] | [Value, Unit]) {
    switch (args.length) {
      case 1: {
        const [{ value, unit }] = args;
        this.unit = unit;

        if (typeof value === "number") {
          this.value = value;
        } else if ("ipart" in value) {
          this.value = new MixedFraction(value);
        } else if ("sig" in value) {
          this.value = new DecimalFraction(value);
        } else if ("num" in value) {
          this.value = new CommonFraction(value);
        } else {
          exhaust(value);
        }

        break;
      }
      case 2: {
        const [value, unit] = args;
        this.value = value;
        this.unit = unit;
        break;
      }
      default:
        exhaust(args);
        break;
    }
  }

  get isImperial(): boolean {
    return this.unit === "feet" || this.unit === "inches";
  }

  toPrimitive(): Quantity {
    return new Quantity(+this.value, this.unit);
  }

  valueOf(): number {
    return this.toNumber();
  }

  toNumber() {
    return +this.value;
  }

  toFixed(precision: number) { }

  /* split(): [Quantity, Quantity] {
        const value = this.value;
        const unit = this.unit;
        let ipart, fpart;

        if (typeof value === 'number') {
            ipart = Math.trunc(value);
            fpart = value - ipart;
        }
    }*/

  convert(targetUnit: Unit): Quantity {
    const targetValue = convert(+this.value, this.unit).to(targetUnit);
    return new Quantity(targetValue, targetUnit);
  }

  clone(): Quantity {
    let unit = this.unit;

    if (typeof this.value === "number") {
      return new Quantity(this.value, unit);
    }
    return new Quantity(this.value.clone(), unit);
  }

  toString() {
    const unit = unitToSymbol(this.unit, {});
    return `${this.value}${unit}`;
  }
}

type PlainDimension = {
  sign?: Sign; // Legacy support
  quants: [PlainQuantity] | [PlainQuantity, PlainQuantity];
};

type Sign = -1 | 1;
function isSign(val: any): val is Sign {
  return val === -1 || val === 1;
}

function signToString(s: Sign): "-" | "" {
  return s === -1 ? "-" : "";
}

type Quantities = [Quantity] | [Quantity, Quantity];

class Dimension {
  sign: Sign;
  quants: Quantities;

  constructor(q: Quantity);
  constructor(p: Quantity, q: Quantity);
  constructor(s: Sign, q: Quantity);
  constructor(s: Sign, p: Quantity, q: Quantity);
  constructor(obj: PlainDimension);
  constructor(
    ...args:
      | Quantities
      | [Sign, ...Quantities]
      | [PlainDimension]
  ) {
    switch (args.length) {

      case 1: {
        const [arg] = args;
        this.sign = 1;

        if ("quants" in arg) {
          this.sign = arg.sign || 1;
          this.quants = arg.quants.map((q) => new Quantity(q!)) as Quantities;
        } else if (isQuantity(arg)) {
          this.quants = [arg];
        } else {
          exhaust(arg);
        }
        break;
      }

      case 2: {
        const [p, q] = args;

        insist(q, isQuantity);

        if (isSign(p)) { // Signed
          this.sign = p;
          this.quants = [q];
        } else if (isQuantity(p)) { // Unsigned
          this.sign = 1;
          this.quants = [p, q];
        } else {
          exhaust(p);
        }
        break;
      }

      case 3: {
        const [s, p, q] = args;

        insist(s, isSign);
        insist(p, isQuantity);
        insist(q, isQuantity);

        this.sign = s;
        this.quants = [p, q];

        break;
      }

      default:
        exhaust(args, "Expected three or fewer arguments");
    }
  }

  static from(value: number, unit: Unit) {
    const s = (Math.sign(value) || 1) as Sign;
    const q = Math.abs(value);
    return new Dimension(s, new Quantity(q, unit));
  }

  get length(): number {
    return this.quants.length;
  }

  get 0(): Quantity {
    return this.quants[0];
  }

  get 1(): Quantity | undefined {
    return this.quants[1];
  }

  get head(): Quantity {
    return this[0];
  }

  get tail(): Quantity {
    return this[1] || this[0];
  }

  toPrimitive(): Dimension {
    var { sign, quants } = this;
    quants = quants.map((q) => q.toPrimitive()) as Quantities;
    return new Dimension({ sign, quants });
  }

  toNumber(unit: Unit): number {
    insist(unit, isUnit, "A valid unit is required to convert a Dimension to a number");
    const converted = this.convert(unit);
    return converted.sign * converted.head.toNumber();
  }

  toFixed(unit: Unit, factionDigits: number | undefined): string {
    insist(unit, isUnit, "A valid unit is required to convert a Dimension to a fixed-point representation");
    return this.toNumber(unit).toFixed(factionDigits);
  }

  convert(targetUnit: Unit): Dimension {
    const { sign, quants } = this;
    const convertedValue = quants.reduce((acc, q) => {
      return acc + q.convert(targetUnit).toNumber();
    }, 0);

    return new Dimension(sign, new Quantity(convertedValue, targetUnit));
  }

  compare(other: Dimension, rtol?: number, atol?: number): number {
    const commonUnit = this.tail.unit;
    const a = this.sign * this.toNumber(commonUnit);
    const b = other.sign * other.toNumber(commonUnit);

    if (isClose(a, b, rtol, atol)) {
      return 0;
    } else if (a < b) {
      return -1;
    } else {
      return 1;
    }
  };

  format(
    format: LinearDisplayFormat,
    precision: LinearDisplayPrecision,
    options: LinearDisplayOptions = {}
  ): string {
    options = { ...defaultDisplayOptions, ...options };

    let targetUnit = options.displayUnit || this.tail.unit;
    let unit = options.showUnit ? unitToSymbol(targetUnit, options) : "";
    const sign = signToString(this.sign);

    switch (format) {
      case LinearDisplayFormat.DECIMAL: {
        const converted = this.convert(targetUnit);
        const value = converted[0].value.valueOf().toFixed(precision); // Safe?
        return `${sign}${value}${unit}`;
      }
      case LinearDisplayFormat.ENGINEERING:
        throw new Error("ENGINEERING display not implemented");
      case LinearDisplayFormat.ARCHITECTURAL:
        throw new Error("ARCHITECTURAL display not implemented");
      case LinearDisplayFormat.FRACTIONAL: {
        const converted = this.convert(targetUnit);
        const value = converted[0].value;

        let [ipart, fpart] = modf(+value); // Safe?
        let [num, den] = num2frac(fpart, precision);

        let frac;
        if (den === 1) {
          if (num === 0) {
            frac = "";
          } else if (num === 1) {
            frac = "";
            ipart += 1;
          } else {
            throw `Unexpected fraction conversion: ${num}/${den}`;
          }
        } else {
          frac = formatFraction(num, den, options);
        }
        return `${sign}${ipart}${frac}${unit}`;
      }
      default:
        throw new TypeError("Unexpected input");
    }
  }

  toString() {
    const sign = signToString(this.sign);
    const quants = this.quants.map((q) => q.toString()).join(" ");
    return `${sign}${quants}`;
  }
}

function isNonNullObject(x: any): boolean {
  return x !== null && typeof x === "object";
}

function hasStructure(data: any, u: string): boolean;
function hasStructure(data: any, u: string, v: string): boolean
function hasStructure(...args: [any, string] | [any, string, string]): boolean {
  const [data] = args;
  if (isNonNullObject(data)) {
    const u = args[1];
    switch (args.length) {
      case 2:
        return u in data;
      case 3:
        const v = args[2];
        return u in data && v in data;
      default:
        exhaust(args);
    }
  }
  return false;
}

function canBeQuantity(data: any): boolean {
  return hasStructure(data, "value", "unit");
}

function canBeCommonFraction(data: any): boolean {
  return hasStructure(data, "num", "den");
}

function canBeDecimalFraction(data: any): boolean {
  return hasStructure(data, "sig", "exp");
}

function canBeMixedFraction(data: any): boolean {
  return hasStructure(data, "ipart", "fpart");
}

function canBeDimension(data: any): boolean {
  return hasStructure(data, "quants", "sign");
}

function revive(data: any) {
  if (canBeQuantity(data)) {
    return new Quantity(data.value, data.unit);
  }

  if (canBeCommonFraction(data)) {
    return new CommonFraction(data.num, data.den);
  }

  if (canBeDecimalFraction(data)) {
    return new DecimalFraction(data.sig, data.exp);
  }

  if (canBeMixedFraction(data)) {
    return new MixedFraction(data.ipart, data.fpart);
  }

  if (canBeDimension(data)) {
    return new Dimension(data);
  }
  return data;
}

function dimReviver(key: string, data: any): any {
  if (!key && Array.isArray(data)) {
    // A top-level array
    // @ts-ignore
    return new Dimension(...data);
  }

  if (typeof data === "object") {
    return revive(data);
  }

  return data;
}

export {
  Dimension,
  CommonFraction,
  DecimalFraction,
  Quantity,
  MixedFraction,
  dimReviver,
  defaultDimTextOptions,
};

export type {
  PlainDimension,
  PlainCommonFraction,
  PlainDecimalFraction,
  PlainQuantity,
  PlainMixedFraction,
  Unit as DimTextUnit
};
