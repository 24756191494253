import get from "lodash/get.js";
import { Dimension, Quantity } from "dimtext";
import {
  scale,
  add,
  rotate,
  subtract,
  normalize,
  midpoint,
  equal,
} from "vector";
import { lt, gt, almostEqual } from "overline";
import { Drawing } from "drawing";
import {
  edgeOffsetCenter,
  edgeOffset as edgeOffsetPoint,
} from "../geometry/edge-offset-center.js";
import { edgeRectanglePoints } from "../geometry/edge-rectangle-points.js";
import {
  edgePointRotation,
  edgePoint,
} from "../geometry/edge-point-rotation.js";
import { cornerFlip } from "../geometry/corner-flip.js";
import { bulgeSagitta, sagittaBulge } from "../geometry/bulge-sagitta.js";
import {
  stretchX,
  stretchXInclusive,
  stretchNegX,
  stretchY,
  stretchYInclusive,
  stretchNegY,
} from "../geometry/stretch-shape.js";
import { fabFunctions } from "../fabrications/index.js";
import markColor from "./mark-color.js";
import rectPosition from "@local/extensions/geometry/rectangular-hole-position.js";
import {
  blxDims,
  brxDims,
  tlxDims,
  trxDims,
  bryDims,
  tryDims,
  blyDims,
  tlyDims,
} from "@local/extensions/utilities/edge-dimensions.js";
import axisDimensions from "@local/extensions/utilities/axis-dimensions.js";
import rakeSymbol from "@local/extensions/drawing/rake-symbol.js";

import Polyface from "../geometry/polyface.js";

function addToMap(map, key, value) {
  if (!map.has(key)) {
    map.set(key, []);
  }
  map.get(key).push(value);
}

function fabPath(f, fab) {
  if (f.orphaned) {
    return { path: f.path, voids: f.voids };
  } else {
    if (fab.type === "function") {
      const func = fabFunctions[fab.func];
      const res = func(...fab.params);
      return { path: res.path, voids: res.voids };
    } else if (fab.type === "generic") {
      const func = fabFunctions[fab.position];
      const res = func();
      return { path: res.path, voids: res.voids, annotations: res.annotations };
    } else {
      return { path: fab.path, voids: fab.voids };
    }
  }
}

function rpStyle(cls, id, highlights) {
  if (highlights?.refplanes[cls]?.[id]) {
    return {
      stroke: "#f00",
      lineDash: [10, 4, 3, 4],
      fill: "transparent",
      lineWidth: 3,
    };
  } else {
    return {
      stroke: "#f00",
      lineDash: [10, 4, 3, 4],
      fill: "transparent",
    };
  }
}

function yDim(y1, y2, x, style, tier = 1) {
  return new Drawing()
    .aligned_dim({ x, y: y1 }, { x, y: y2 }, { tier })
    .style(style);
}

function ySpanDim(bv, tv, shape, x, ymin, ymax, style, tier = 1) {
  const min = Math.max(
    ...bv.map(([li, vi]) => {
      const v = shape[li][vi];
      return v.y;
    }),
    ymin,
  );
  const max = Math.min(
    ...tv.map(([li, vi]) => {
      const v = shape[li][vi];
      return v.y;
    }),
    ymax,
  );
  return yDim(min, max, x, style, tier);
}

function xDim(x1, x2, y, style, tier = 1) {
  return new Drawing()
    .aligned_dim({ x: x1, y }, { x: x2, y }, { tier })
    .style(style);
}

function xSpanDim(lv, rv, shape, y, xmin, xmax, style, tier = 1) {
  const min = Math.max(
    ...lv.map(([li, vi]) => {
      const v = shape[li][vi];
      return v.x;
    }),
    xmin,
  );
  const max = Math.min(
    ...rv.map(([li, vi]) => {
      const v = shape[li][vi];
      return v.x;
    }),
    xmax,
  );
  return xDim(min, max, y, style, tier);
}

function liteDrawingDetailed(item, type = {}, fabrications = {}, options = {}) {
  if (item.drawing) {
    const bb = 50;

    if (options.drawing) {
      const ar = options.drawing.width / options.drawing.height;
      const w = ar > 1 ? bb : ar * bb;
      const h = ar > 1 ? bb / ar : bb;
      const x = (bb - w) / 2;

      return {
        drawing: new Drawing()
          .image(options.drawing.image, { x, y: h }, w, h, {
            format: options.drawing.format,
          })
          .rectangle(x, 0, w, h)
          .style({ stroke: "#333", fill: "transparent" }),
        hitboxDrawing: new Drawing(),
      };
    } else {
      let w, h;

      if (item.drawing.properties) {
        const ar =
          item.drawing.properties.width / item.drawing.properties.height;
        w = bb;
        h = w / ar;
      } else {
        w = bb;
        h = bb;
      }

      return {
        drawing: new Drawing()
          .rectangle(0, 0, w, h)
          .style({ stroke: "#333", fill: "transparent" }),
        hitboxDrawing: new Drawing(),
      };
    }
  }

  const polyface = new Polyface(item, fabrications);
  const swf = polyface.shapeWithFeatures;
  const sf = polyface.surfaceFeatures;
  const { xmin, xmax, ymin, ymax, width, height } = polyface.bbox;

  // Get extents of vertices, which may differ from bounding box (due to arc segments)
  const vertices = polyface.vertices;
  let vxmin = vertices[0]?.x;
  let vxmax = vertices[0]?.x;
  let vymin = vertices[0]?.y;
  let vymax = vertices[0]?.y;
  vertices.slice(1).forEach((v) => {
    if (v.x < vxmin) vxmin = v.x;
    if (v.x > vxmax) vxmax = v.x;
    if (v.y < vymin) vymin = v.y;
    if (v.y > vymax) vymax = v.y;
  });

  const edges = polyface.edges;
  const cornerFabs = polyface.cornerFabs;
  let offsets;

  const isShape = !!(item.width && item.height);

  const dimConversion = options.displayUnit === "millimeters" ? 25.4 : 1;

  const dimstyle = {
    stroke: "#888",
    annoFormat: options.dimFormat,
    annoPrecision: options.dimPrecision,
    dimConversion,
  };

  const gds = {
    stroke: options.uniformDimColor ? "#888" : "#ccc",
    textColor: options.uniformDimColor ? "#000" : "#aaa",
    fill: options.uniformDimColor ? "#888" : "#ccc",
    annoFormat: options.dimFormat,
    annoPrecision: options.dimPrecision,
    dimConversion,
  };

  let solidFill;
  if (options.isCollection) {
    solidFill = "#fff";
  } else if (item.is_imported) {
    solidFill = "#F7F7B7";
  } else if (options.typeColor) {
    solidFill = options.typeColor;
  } else {
    solidFill = "#B7D7F7";
  }

  const solidStyle = {
    stroke: "#333",
    fill: solidFill,
  };
  const partiallyDefinedStyle = {
    stroke: "#333",
    fill: "#ddd",
    lineDash: [3, 3],
  };
  const offsetStyle = {
    stroke: "#333",
    lineDash: [5, 3],
    lineWidth: 0.5,
    fill: "transparent",
  };

  const shape = new Drawing()
    .polyface(swf.shape)
    .style(isShape ? solidStyle : partiallyDefinedStyle)
    .name("shape");

  let context = null;
  if (options.context && options.context.record_id === item.id) {
    context = new Drawing().image(
      options.context.img,
      { x: options.context.x, y: options.context.y },
      options.context.width,
      options.context.height,
      { opacity: 0.4, format: options.context.format },
    );
  }

  const surfaceFeatures = sf.map((f) =>
    new Drawing().polyface([f]).style({ stroke: "#333", fill: "transparent" }),
  );

  const markStyle = { stroke: "#333", fill: "transparent" };
  const labelBy = options.labelBy || "mark";
  const markText = (get(item, labelBy) || "").toString().trim();

  const mark = new Drawing()
    .mark({ x: xmin + width / 2, y: ymin }, markText, {
      shape: options.isCollection ? "rectangle" : "hexagon",
      offset: { x: 0, y: -25 },
    })
    .style(markColor(item))
    .name("mark_mark");

  const typeMark = new Drawing()
    .mark({ x: xmin + width / 2, y: ymin + height / 2 }, type.mark, {
      shape: "circle",
    })
    .style(markStyle)
    .name("type_type_id");

  if (
    isShape &&
    polyface &&
    item.data.reference_planes?.type !== "box" &&
    polyface.offsets
  ) {
    offsets = new Drawing()
      .polyface(polyface.offsets)
      .style(offsetStyle)
      .name("offsets");
  }

  const fabMarks = [];
  let xDims = [];
  let yDims = [];

  let mx = xmin + (xmax - xmin) / 2;
  let my = ymin + (ymax - ymin) / 2;

  let nxmx = xmax;
  let nxmn = xmin;
  let nymx = ymax;
  let nymn = ymin;

  let x1 = xmin;
  let x2 = xmax;
  let y1 = ymin;
  let y2 = ymax;

  let hor = ymin + height / 2;
  let ver = xmin + width / 2;

  if (item.data.reference_planes?.type === "box") {
    x1 = item.data.reference_planes.left.value.toNumber("inches");
    x2 = item.data.reference_planes.right.value.toNumber("inches");
    y1 = item.data.reference_planes.bottom.value.toNumber("inches");
    y2 = item.data.reference_planes.top.value.toNumber("inches");

    mx = x1 + (x2 - x1) / 2;
    my = y1 + (y2 - y1) / 2;

    nxmx = Math.max(xmax, x2);
    nxmn = Math.min(xmin, x1);
    nymx = Math.max(ymax, y2);
    nymn = Math.min(ymin, y1);
  } else if (item.data.reference_planes?.type === "cross") {
    hor = item.data.reference_planes.horizontal.value.toNumber("inches");
    ver = item.data.reference_planes.vertical.value.toNumber("inches");

    nxmx = Math.max(xmax, ver);
    nxmn = Math.min(xmin, ver);
    nymx = Math.max(ymax, hor);
    nymn = Math.min(ymin, hor);
  }

  let trimbl = false;
  let trimbr = false;
  let trimlt = false;
  let trimlb = false;
  let xTier = -1;
  let xDimsPos = [
    { x: xmin, y: nymn },
    { x: xmax, y: nymn },
  ];
  let yTier = 1;
  let yDimsPos = [
    { x: nxmn, y: ymin },
    { x: nxmn, y: ymax },
  ];

  if (options.showTickDims) {
    const blv = [];
    const brv = [];
    const tlv = [];
    const trv = [];

    const blxTicks = new Map();
    const blyTicks = new Map();
    const brxTicks = new Map();
    const bryTicks = new Map();
    const tlxTicks = new Map();
    const tlyTicks = new Map();
    const trxTicks = new Map();
    const tryTicks = new Map();
    const taxTicks = new Map();
    const rayTicks = new Map();
    const baxTicks = new Map();
    const layTicks = new Map();

    if (item.data.reference_planes?.type === "cross") {
      const vsplit = ver > xmin && ver < xmax;
      const hsplit = hor > ymin && hor < ymax;
      const vdiv = vsplit ? ver : mx;
      const hdiv = hsplit ? hor : my;

      polyface.shape.forEach((loop, li) => {
        loop.forEach((v, vi) => {
          if ((v.x > vxmin && v.x < vxmax) || (v.y > vymin && v.y < vymax)) {
            if (v.x < vdiv && v.y < hdiv) blv.push([li, vi]);
            else if (v.x >= vdiv && v.y < hdiv) brv.push([li, vi]);
            else if (v.x < vdiv && v.y >= hdiv) tlv.push([li, vi]);
            else if (v.x >= vdiv && v.y >= hdiv) trv.push([li, vi]);
          }
        });
      });

      blv.forEach((p) => {
        const v = polyface.shape[p[0]][p[1]];

        if (v.x > xmin) {
          if (vsplit) {
            const a = v.x - xmin;
            const b = Math.abs(v.x - ver);
            if (a < b) {
              addToMap(blxTicks, v.x, p);
            } else {
              addToMap(baxTicks, v.x, p);
            }
          } else {
            addToMap(blxTicks, v.x, p);
          }
        }

        if (v.y > ymin) {
          if (hsplit) {
            const a = v.y - ymin;
            const b = Math.abs(v.y - hor);
            if (a < b) {
              addToMap(blyTicks, v.y, p);
            } else {
              addToMap(layTicks, v.y, p);
            }
          } else {
            addToMap(blyTicks, v.y, p);
          }
        }
      });

      brv.forEach((p) => {
        const v = polyface.shape[p[0]][p[1]];

        if (v.x < xmax) {
          if (vsplit) {
            const a = xmax - v.x;
            const b = Math.abs(v.x - ver);
            if (a < b) {
              addToMap(brxTicks, v.x, p);
            } else {
              addToMap(baxTicks, v.x, p);
            }
          } else {
            addToMap(brxTicks, v.x, p);
          }
        }
        if (v.y > ymin) {
          if (hsplit) {
            const a = v.y - ymin;
            const b = Math.abs(v.y - hor);
            if (a < b) {
              addToMap(bryTicks, v.y, p);
            } else {
              addToMap(rayTicks, v.y, p);
            }
          } else {
            addToMap(bryTicks, v.y, p);
          }
        }
      });

      tlv.forEach((p) => {
        const v = polyface.shape[p[0]][p[1]];

        if (v.x > xmin) {
          if (vsplit) {
            const a = v.x - xmin;
            const b = Math.abs(v.x - ver);
            if (a < b) {
              addToMap(tlxTicks, v.x, p);
            } else {
              addToMap(taxTicks, v.x, p);
            }
          } else {
            addToMap(tlxTicks, v.x, p);
          }
        }
        if (v.y < ymax) {
          if (hsplit) {
            const a = ymax - v.y;
            const b = Math.abs(v.y - hor);
            if (a < b) {
              addToMap(tlyTicks, v.y, p);
            } else {
              addToMap(layTicks, v.y, p);
            }
          } else {
            addToMap(tlyTicks, v.y, p);
          }
        }
      });

      trv.forEach((p) => {
        const v = polyface.shape[p[0]][p[1]];

        if (v.x < xmax) {
          if (vsplit) {
            const a = xmax - v.x;
            const b = Math.abs(v.x - ver);
            if (a < b) {
              addToMap(trxTicks, v.x, p);
            } else {
              addToMap(taxTicks, v.x, p);
            }
          } else {
            addToMap(trxTicks, v.x, p);
          }
        }
        if (v.y < ymax) {
          if (hsplit) {
            const a = ymax - v.y;
            const b = Math.abs(v.y - hor);
            if (a < b) {
              addToMap(tryTicks, v.y, p);
            } else {
              addToMap(rayTicks, v.y, p);
            }
          } else {
            addToMap(tryTicks, v.y, p);
          }
        }
      });

      const leftTicks =
        blyTicks.size > 0 ||
        tlyTicks.size > 0 ||
        layTicks.size > 0 ||
        lt(vymax, ymax) ||
        gt(vymin, ymin);
      const rightTicks =
        bryTicks.size > 0 || tryTicks.size > 0 || rayTicks.size > 0;
      const topTicks =
        tlxTicks.size > 0 || trxTicks.size > 0 || taxTicks.size > 0;
      const bottomTicks =
        blxTicks.size > 0 ||
        brxTicks.size > 0 ||
        baxTicks.size > 0 ||
        lt(vxmax, xmax) ||
        gt(vxmin, xmin);

      if (leftTicks && rightTicks) {
        yTier = 2.5;
      } else if (leftTicks) {
        yTier = -1;
        yDimsPos = [
          { x: nxmx, y: ymin },
          { x: nxmx, y: ymax },
        ];
      }

      if (topTicks && bottomTicks) {
        xTier = -2;
      } else if (bottomTicks) {
        xTier = 1;
        xDimsPos = [
          { x: xmin, y: nymx },
          { x: xmax, y: nymx },
        ];
      }

      if (blxTicks.size > 0 && topTicks && bottomTicks) trimbl = true;
      if (brxTicks.size > 0 && topTicks && bottomTicks) trimbr = true;
      if (tlyTicks.size > 0 && leftTicks && rightTicks) trimlt = true;
      if (blyTicks.size > 0 && leftTicks && rightTicks) trimlb = true;

      const extents = { xmin: vxmin, xmax: vxmax, ymin: vymin, ymax: vymax };
      const dx = {
        xmin: nxmn,
        xmax: nxmx,
        ymin: nymn,
        ymax: nymx,
        horizontal: hor,
        vertical: ver,
      };

      xDims.push(
        ...blxDims(blxTicks, polyface, dimstyle, extents, dx),
        ...brxDims(brxTicks, polyface, dimstyle, extents, dx),
        ...tlxDims(tlxTicks, polyface, dimstyle, extents, dx),
        ...trxDims(trxTicks, polyface, dimstyle, extents, dx),
        ...axisDimensions(
          baxTicks,
          polyface,
          dimstyle,
          ver,
          nymn,
          "vertical",
          "bottom",
        ),
        ...axisDimensions(
          taxTicks,
          polyface,
          dimstyle,
          ver,
          nymx,
          "vertical",
          "top",
        ),
      );

      yDims.push(
        ...blyDims(blyTicks, polyface, dimstyle, extents, dx),
        ...bryDims(bryTicks, polyface, dimstyle, extents, dx),
        ...tlyDims(tlyTicks, polyface, dimstyle, extents, dx),
        ...tryDims(tryTicks, polyface, dimstyle, extents, dx),
        ...axisDimensions(
          rayTicks,
          polyface,
          dimstyle,
          hor,
          nxmx,
          "horizontal",
          "right",
        ),
        ...axisDimensions(
          layTicks,
          polyface,
          dimstyle,
          hor,
          nxmn,
          "horizontal",
          "left",
        ),
      );

      if (options.showSpanningDims) {
        if (blxTicks.size > 0 || baxTicks.size > 0 || brxTicks.size > 0) {
          if (vsplit) {
            const lmin = Math.max(...blxTicks.keys(), vxmin);
            const lmax = Math.min(...baxTicks.keys(), ver);
            xDims.push(xDim(lmin, lmax, nymn, gds, -1));

            const rmin = Math.max(...baxTicks.keys(), ver);
            const rmax = Math.min(...brxTicks.keys(), vxmax);
            xDims.push(xDim(rmin, rmax, nymn, gds, -1));
          } else {
            xDims.push(
              xSpanDim(blv, brv, polyface.shape, nymn, vxmin, vxmax, gds, -1),
            );
          }
        }

        if (tlxTicks.size > 0 || taxTicks.size > 0 || trxTicks.size > 0) {
          if (vsplit) {
            const lmin = Math.max(...tlxTicks.keys(), vxmin);
            const lmax = Math.min(...taxTicks.keys(), ver);
            xDims.push(xDim(lmin, lmax, nymx, gds));

            const rmin = Math.max(...taxTicks.keys(), ver);
            const rmax = Math.min(...trxTicks.keys(), vxmax);
            xDims.push(xDim(rmin, rmax, nymx, gds));
          } else {
            yDims.push(
              ySpanDim(tlv, trv, polyface.shape, nxmn, vymin, vymax, gds, 1),
            );
          }
        }

        if (blyTicks.size > 0 || layTicks.size > 0 || tlyTicks.size > 0) {
          if (hsplit) {
            const bmin = Math.max(...blyTicks.keys(), vymin);
            const bmax = Math.min(...layTicks.keys(), hor);
            yDims.push(yDim(bmin, bmax, nxmn, gds));

            const tmin = Math.max(...layTicks.keys(), hor);
            const tmax = Math.min(...tlyTicks.keys(), vymax);
            yDims.push(yDim(tmin, tmax, nxmn, gds));
          } else {
            yDims.push(
              ySpanDim(blv, tlv, polyface.shape, nxmn, vymin, vymax, gds, 1),
            );
          }
        }

        if (bryTicks.size > 0 || rayTicks.size > 0 || tryTicks.size > 0) {
          if (hsplit) {
            const bmin = Math.max(...bryTicks.keys(), vymin);
            const bmax = Math.min(...rayTicks.keys(), hor);
            yDims.push(yDim(bmin, bmax, nxmx, gds, -1));

            const tmin = Math.max(...rayTicks.keys(), hor);
            const tmax = Math.min(...tryTicks.keys(), vymax);
            yDims.push(yDim(tmin, tmax, nxmx, gds, -1));
          } else {
            xDims.push(
              xSpanDim(brv, trv, polyface.shape, nxmx, vxmin, vxmax, gds, -1),
            );
          }
        }
      }
    } else {
      if (item.data.reference_planes?.type === "box") {
        // Is shape a quad with two vertical edges?
        const vquad =
          vertices.length === 4 &&
          almostEqual(vertices[0].x, vertices[3].x) &&
          almostEqual(vertices[1].x, vertices[2].x);

        // Is shape a quad with two horizontal edges?
        const hquad =
          vertices.length === 4 &&
          almostEqual(vertices[0].y, vertices[1].y) &&
          almostEqual(vertices[2].y, vertices[3].y);

        polyface.shape.forEach((loop, li) => {
          loop.forEach((v, vi) => {
            if (
              (!almostEqual(v.x, x1) && !almostEqual(v.x, x2)) ||
              (!almostEqual(v.y, y1) && !almostEqual(v.y, y2))
            ) {
              if (v.x < mx && v.y < my) blv.push([li, vi]);
              else if (v.x >= mx && v.y < my) brv.push([li, vi]);
              else if (v.x < mx && v.y >= my) tlv.push([li, vi]);
              else if (v.x >= mx && v.y >= my) trv.push([li, vi]);
            }
          });
        });

        blv.forEach((p) => {
          const v = polyface.shape[p[0]][p[1]];
          if (v.x !== x1 && !vquad) addToMap(blxTicks, v.x, p);
          if (v.y !== y1 && !hquad) addToMap(blyTicks, v.y, p);
        });

        brv.forEach((p) => {
          const v = polyface.shape[p[0]][p[1]];
          if (v.x !== x2 && !vquad) addToMap(brxTicks, v.x, p);
          if (v.y !== y1 && !hquad) addToMap(bryTicks, v.y, p);
        });

        tlv.forEach((p) => {
          const v = polyface.shape[p[0]][p[1]];
          if (v.x !== x1 && !vquad) addToMap(tlxTicks, v.x, p);
          if (v.y !== y2 && !hquad) addToMap(tlyTicks, v.y, p);
        });

        trv.forEach((p) => {
          const v = polyface.shape[p[0]][p[1]];
          if (v.x !== x2 && !vquad) addToMap(trxTicks, v.x, p);
          if (v.y !== y2 && !hquad) addToMap(tryTicks, v.y, p);
        });

        if (hquad) {
          if (!almostEqual(vymax, y2)) {
            addToMap(tryTicks, vymax, [0, 2]);
            addToMap(tryTicks, vymax, [0, 3]);
          }

          if (!almostEqual(vymin, y1)) {
            addToMap(bryTicks, vymin, [0, 0]);
            addToMap(bryTicks, vymin, [0, 1]);
          }
        }

        if (vquad) {
          if (!almostEqual(vxmax, x2)) {
            addToMap(trxTicks, vxmax, [0, 1]);
            addToMap(trxTicks, vxmax, [0, 2]);
          }

          if (!almostEqual(vxmin, x1)) {
            addToMap(tlxTicks, vxmin, [0, 0]);
            addToMap(tlxTicks, vxmin, [0, 3]);
          }
        }
      } else {
        polyface.shape.forEach((loop, li) => {
          loop.forEach((v, vi) => {
            if ((v.x > vxmin && v.x < vxmax) || (v.y > vymin && v.y < vymax)) {
              if (v.x < mx && v.y < my) blv.push([li, vi]);
              else if (v.x >= mx && v.y < my) brv.push([li, vi]);
              else if (v.x < mx && v.y >= my) tlv.push([li, vi]);
              else if (v.x >= mx && v.y >= my) trv.push([li, vi]);
            }
          });
        });

        blv.forEach((p) => {
          const v = polyface.shape[p[0]][p[1]];
          if (v.x > xmin) addToMap(blxTicks, v.x, p);
          if (v.y > ymin) addToMap(blyTicks, v.y, p);
        });

        brv.forEach((p) => {
          const v = polyface.shape[p[0]][p[1]];
          if (v.x < xmax) addToMap(brxTicks, v.x, p);
          if (v.y > ymin) addToMap(bryTicks, v.y, p);
        });

        tlv.forEach((p) => {
          const v = polyface.shape[p[0]][p[1]];
          if (v.x > xmin) addToMap(tlxTicks, v.x, p);
          if (v.y < ymax) addToMap(tlyTicks, v.y, p);
        });

        trv.forEach((p) => {
          const v = polyface.shape[p[0]][p[1]];
          if (v.x < xmax) addToMap(trxTicks, v.x, p);
          if (v.y < ymax) addToMap(tryTicks, v.y, p);
        });
      }

      const extents =
        item.data.reference_planes?.type === "box"
          ? { xmin: x1, xmax: x2, ymin: y1, ymax: y2 }
          : { xmin: vxmin, xmax: vxmax, ymin: vymin, ymax: vymax };
      const dx = { xmin: nxmn, xmax: nxmx, ymin: nymn, ymax: nymx };

      const leftTicks =
        blyTicks.size > 0 ||
        tlyTicks.size > 0 ||
        lt(vymax, ymax) ||
        gt(vymin, ymin);
      const rightTicks = bryTicks.size > 0 || tryTicks.size > 0;
      const topTicks = tlxTicks.size > 0 || trxTicks.size > 0;
      const bottomTicks =
        blxTicks.size > 0 ||
        brxTicks.size > 0 ||
        lt(vxmax, xmax) ||
        gt(vxmin, xmin);

      if (leftTicks && rightTicks) {
        yTier = 2.5;
      } else if (leftTicks) {
        yTier = -1;
        yDimsPos = [
          { x: nxmx, y: ymin },
          { x: nxmx, y: ymax },
        ];
      }

      if (topTicks && bottomTicks) {
        xTier = -2;
      } else if (bottomTicks) {
        xTier = 1;
        xDimsPos = [
          { x: xmin, y: nymx },
          { x: xmax, y: nymx },
        ];
      }

      if (blxTicks.size > 0 && topTicks && bottomTicks) trimbl = true;
      if (brxTicks.size > 0 && topTicks && bottomTicks) trimbr = true;
      if (tlyTicks.size > 0 && leftTicks && rightTicks) trimlt = true;
      if (blyTicks.size > 0 && leftTicks && rightTicks) trimlb = true;

      if (options.showSpanningDims) {
        if (blyTicks.size > 0 || tlyTicks.size > 0) {
          yDims.push(
            ySpanDim(blv, tlv, polyface.shape, nxmn, vymin, vymax, gds),
          );
        }

        if (bryTicks.size > 0 || tryTicks.size > 0) {
          yDims.push(
            ySpanDim(brv, trv, polyface.shape, nxmx, vymin, vymax, gds, -1),
          );
        }

        if (blxTicks.size > 0 || brxTicks.size > 0) {
          xDims.push(
            xSpanDim(blv, brv, polyface.shape, nymn, vxmin, vxmax, gds, -1),
          );
        }

        if (tlxTicks.size > 0 || trxTicks.size > 0) {
          xDims.push(
            xSpanDim(tlv, trv, polyface.shape, nymx, vxmin, vxmax, gds),
          );
        }
      }

      xDims.push(
        ...blxDims(blxTicks, polyface, dimstyle, extents, dx),
        ...brxDims(brxTicks, polyface, dimstyle, extents, dx),
        ...tlxDims(tlxTicks, polyface, dimstyle, extents, dx),
        ...trxDims(trxTicks, polyface, dimstyle, extents, dx),
      );

      yDims.push(
        ...blyDims(blyTicks, polyface, dimstyle, extents, dx),
        ...bryDims(bryTicks, polyface, dimstyle, extents, dx),
        ...tlyDims(tlyTicks, polyface, dimstyle, extents, dx),
        ...tryDims(tryTicks, polyface, dimstyle, extents, dx),
      );
    }

    // Informational dimensions for arc segments
    if (gt(vxmin, xmin)) {
      yDims.push(
        new Drawing()
          .aligned_dim(
            { x: xmin, y: nymn },
            { x: vxmin, y: nymn },
            { tier: -1 },
          )
          .style(gds),
      );
    }

    if (lt(vxmax, xmax)) {
      yDims.push(
        new Drawing()
          .aligned_dim(
            { x: vxmax, y: nymn },
            { x: xmax, y: nymn },
            { tier: -1 },
          )
          .style(gds),
      );
    }

    if (gt(vymin, ymin)) {
      xDims.push(
        new Drawing()
          .aligned_dim({ x: nxmn, y: ymin }, { x: nxmn, y: vymin }, { tier: 1 })
          .style(gds),
      );
    }

    if (lt(vymax, ymax)) {
      xDims.push(
        new Drawing()
          .aligned_dim({ x: nxmn, y: vymax }, { x: nxmn, y: ymax }, { tier: 1 })
          .style(gds),
      );
    }

    const leftTicks =
      blyTicks.size > 0 || tlyTicks.size > 0 || layTicks.size > 0;

    const bottomTicks =
      blxTicks.size > 0 || brxTicks.size > 0 || baxTicks.size > 0;

    if ((gt(vxmin, xmin) || lt(vxmax, xmax)) && !bottomTicks) {
      xDims.push(
        new Drawing()
          .aligned_dim(
            { x: vxmin, y: nymn },
            { x: vxmax, y: nymn },
            {
              tier: -1,
              callback: (v) => {
                const dx = v - (vxmax - vxmin);
                return stretchXInclusive(dx, polyface.shape, mx);
              },
            },
          )
          .style(dimstyle)
          .name("dim_widthvertsonly"),
      );
    }

    if ((gt(vymin, ymin) || lt(vymax, ymax)) && !leftTicks) {
      yDims.push(
        new Drawing()
          .aligned_dim(
            { x: nxmn, y: vymin },
            { x: nxmn, y: vymax },
            {
              tier: 1,
              callback: (v) => {
                const dy = v - (vymax - vymin);
                return stretchYInclusive(dy, polyface.shape, my);
              },
            },
          )
          .style(dimstyle)
          .name("dim_heightvertsonly"),
      );
    }
  }

  // offset dimensions
  const refPlanes = [];
  const rpHitBoxes = [];
  if (item.data.reference_planes?.type === "box") {
    const height = nymx - nymn;
    const width = nxmx - nxmn;

    const ht = height * 0.05;
    const wt = width * 0.05;
    const ext = Math.max(ht, wt);

    const xm0 = nxmn - ext;
    const xm1 = nxmx + ext;
    const ym0 = nymn - ext;
    const ym1 = nymx + ext;

    refPlanes.push(
      new Drawing()
        .polyline([
          { x: x1, y: ym1 },
          { x: x1, y: ym0 },
        ])
        .style(rpStyle("box", "left", options.highlights)),
      new Drawing()
        .polyline([
          { x: x2, y: ym1 },
          { x: x2, y: ym0 },
        ])
        .style(rpStyle("box", "right", options.highlights)),
      new Drawing()
        .polyline([
          { x: xm0, y: y1 },
          { x: xm1, y: y1 },
        ])
        .style(rpStyle("box", "bottom", options.highlights)),
      new Drawing()
        .polyline([
          { x: xm0, y: y2 },
          { x: xm1, y: y2 },
        ])
        .style(rpStyle("box", "top", options.highlights)),
    );

    rpHitBoxes.push(
      new Drawing()
        .polyline([
          { x: x1, y: ym1 },
          { x: x1, y: ym0 },
        ])
        .name("refplane_box_left"),
      new Drawing()
        .polyline([
          { x: x2, y: ym1 },
          { x: x2, y: ym0 },
        ])
        .name("refplane_box_right"),
      new Drawing()
        .polyline([
          { x: xm0, y: y1 },
          { x: xm1, y: y1 },
        ])
        .name("refplane_box_bottom"),
      new Drawing()
        .polyline([
          { x: xm0, y: y2 },
          { x: xm1, y: y2 },
        ])
        .name("refplane_box_top"),
    );
  } else if (item.data.reference_planes?.type === "cross") {
    const height = nymx - nymn;
    const width = nxmx - nxmn;

    const ht = height * 0.05;
    const wt = width * 0.05;
    const ext = Math.max(ht, wt);

    const xm0 = nxmn - ext;
    const xm1 = nxmx + ext;
    const ym0 = nymn - ext;
    const ym1 = nymx + ext;

    refPlanes.push(
      new Drawing()
        .polyline([
          { x: ver, y: ym1 },
          { x: ver, y: ym0 },
        ])
        .style(rpStyle("cross", "vertical", options.highlights)),
      new Drawing()
        .polyline([
          { x: xm0, y: hor },
          { x: xm1, y: hor },
        ])
        .style(rpStyle("cross", "horizontal", options.highlights)),
    );

    rpHitBoxes.push(
      new Drawing()
        .polyline([
          { x: ver, y: ym1 },
          { x: ver, y: ym0 },
        ])
        .name("refplane_cross_vertical"),
      new Drawing()
        .polyline([
          { x: xm0, y: hor },
          { x: xm1, y: hor },
        ])
        .name("refplane_cross_horizontal"),
    );
  }

  // overall width & height dimensions
  if (item.width && item.data.reference_planes?.type === "cross") {
    const vsplit = ver > xmin && ver < xmax;
    if (vsplit) {
      const sTier = xTier;
      const s = Math.sign(xTier);
      xTier = xTier + s;

      const a = xDimsPos[0];
      const b = { x: ver, y: xDimsPos[0].y };
      const c = xDimsPos[1];

      xDims.push(
        new Drawing()
          .aligned_dim(a, b, {
            tier: sTier,
            callback: (v) => {
              const dx = ver - xmin - v;
              return stretchNegX(dx, polyface.shape, ver);
            },
            trimStartWitness: trimbl,
          })
          .style(dimstyle)
          .name("dim_widthbl"),
        new Drawing()
          .aligned_dim(b, c, {
            tier: sTier,
            callback: (v) => {
              const dx = ver + v - xmax;
              return stretchX(dx, polyface.shape, ver);
            },
            trimEndWitness: trimbr,
          })
          .style(dimstyle)
          .name("dim_widthbr"),
        new Drawing()
          .aligned_dim(...xDimsPos, {
            tier: xTier,
            trimStartWitness: trimbl,
            trimEndWitness: trimbr,
          })
          .style(gds),
      );
    } else if (ver !== xmin && ver !== xmax) {
      const y = xDimsPos[0].y;
      const a = ver > xmax ? { x: xmax, y } : { x: xmin, y };
      const b = { x: ver, y };
      const pts = [a, b].sort((a, b) => a.x - b.x);
      xDims.push(
        new Drawing()
          .aligned_dim(...pts, {
            tier: xTier,
            callback: (v) => {
              if (ver > xmax) {
                const dx = ver - xmax - v;
                return stretchNegX(dx, polyface.shape, ver);
              } else {
                const dx = v - xmin + ver;
                return stretchX(dx, polyface.shape, ver);
              }
            },
            trimStartWitness: ver > xmax ? trimbr : trimbl,
            trimEndWitness: ver > xmax ? trimbr : trimbl,
          })
          .style(dimstyle)
          .name("dim_veraxis"),
      );

      xDims.push(
        new Drawing()
          .aligned_dim(...xDimsPos, {
            tier: xTier,
            callback: (v) => {
              if (ver > xmax) {
                const dx = width - v;
                return stretchNegX(dx, polyface.shape, xmax);
              } else {
                const dx = v - width;
                return stretchX(dx, polyface.shape, xmin);
              }
            },
            trimStartWitness: trimbl,
            trimEndWitness: trimbr,
          })
          .style(dimstyle)
          .name("dim_width"),
      );
    }
  } else if (item.data.reference_planes?.type === "box") {
    const y = xDimsPos[0].y;
    const a = { x: x1, y };
    const b = { x: x2, y };

    xDims.push(
      new Drawing()
        .aligned_dim(a, b, {
          tier: xTier,
          callback: (v) => {
            const dx = v - (x2 - x1);
            const stretched = stretchXInclusive(dx, polyface.shape, mx);

            const val = x1 + v;
            return [
              ...stretched,
              {
                path: "data.reference_planes.right.value",
                value: new Dimension(new Quantity(val, "inches")),
              },
            ];
          },
          trimStartWitness: trimbl,
          trimEndWitness: trimbr,
        })
        .style(dimstyle)
        .name("dim_refplaneright"),
    );
  } else {
    xDims.push(
      new Drawing()
        .aligned_dim(...xDimsPos, {
          tier: xTier,
          callback: (v) => {
            const dx = v - width;
            return stretchXInclusive(dx, polyface.shape, mx);
          },
          override: item.width ? null : "W",
          trimStartWitness: trimbl,
          trimEndWitness: trimbr,
        })
        .style(dimstyle)
        .name(item.width ? "dim_width" : "dimonly_width"),
    );
  }

  if (item.height && item.data.reference_planes?.type === "cross") {
    const hsplit = hor > ymin && hor < ymax;

    if (hsplit) {
      const sTier = yTier === 2.5 ? 2 : yTier;
      const s = Math.sign(yTier);
      yTier = yTier + s;
      const x = yDimsPos[0].x;

      const a = yDimsPos[0];
      const b = { x, y: hor };
      const c = yDimsPos[1];

      yDims.push(
        new Drawing()
          .aligned_dim(a, b, {
            tier: sTier,
            callback: (v) => {
              const dy = hor - ymin - v;
              return stretchNegY(dy, polyface.shape, hor);
            },
            trimStartWitness: trimlb,
          })
          .style(dimstyle)
          .name("dim_heightlb"),
        new Drawing()
          .aligned_dim(b, c, {
            tier: sTier,
            callback: (v) => {
              const dy = hor + v - ymax;
              return stretchY(dy, polyface.shape, hor);
            },
            trimEndWitness: trimlt,
          })
          .style(dimstyle)
          .name("dim_heightlt"),
        new Drawing()
          .aligned_dim(...yDimsPos, {
            tier: yTier,
            trimStartWitness: trimlt,
            trimEndWitness: trimlb,
          })
          .style(gds),
      );
    } else if (hor !== ymin && hor !== ymax) {
      const x = yDimsPos[0].x;
      const a = hor > ymax ? { x, y: ymax } : { x, y: ymin };
      const b = { x, y: hor };
      const pts = [a, b].sort((a, b) => a.y - b.y);
      yDims.push(
        new Drawing()
          .aligned_dim(...pts, {
            tier: yTier,
            callback: (v) => {
              if (hor > ymax) {
                const dy = hor - ymax - v;
                return stretchNegY(dy, polyface.shape, hor);
              } else {
                const dy = v + hor - ymin;
                return stretchY(dy, polyface.shape, hor);
              }
            },
            trimStartWitness: hor > ymax ? trimlt : trimlb,
            trimEndWitness: hor > ymax ? trimlt : trimlb,
          })
          .style(dimstyle)
          .name("dim_horaxis"),
      );

      yDims.push(
        new Drawing()
          .aligned_dim(...yDimsPos, {
            tier: yTier,
            callback: (v) => {
              if (hor > ymax) {
                const dy = height - v;
                return stretchNegY(dy, polyface.shape, ymax);
              } else {
                const dy = v - height;
                return stretchY(dy, polyface.shape, ymin);
              }
            },
            trimStartWitness: trimlt,
            trimEndWitness: trimlb,
          })
          .style(dimstyle)
          .name("dim_height"),
      );
    }
  } else if (item.data.reference_planes?.type === "box") {
    const x = yDimsPos[0].x;
    const a = { x, y: y1 };
    const b = { x, y: y2 };

    yDims.push(
      new Drawing()
        .aligned_dim(a, b, {
          tier: yTier,
          callback: (v) => {
            const dy = v - (y2 - y1);
            const stretched = stretchYInclusive(dy, polyface.shape, my);

            const val = y1 + v;
            return [
              ...stretched,
              {
                path: "data.reference_planes.top.value",
                value: new Dimension(new Quantity(val, "inches")),
              },
            ];
          },
          trimStartWitness: trimlb,
          trimEndWitness: trimlt,
        })
        .style(dimstyle)
        .name("dim_refplanetop"),
    );
  } else {
    yDims.push(
      new Drawing()
        .aligned_dim(...yDimsPos, {
          tier: yTier,
          callback: (v) => {
            const dy = v - height;
            return stretchYInclusive(dy, polyface.shape, my);
          },
          override: item.height ? null : "H",
          trimStartWitness: trimlb,
          trimEndWitness: trimlt,
        })
        .style(dimstyle)
        .name(item.height ? "dim_height" : "dimonly_height"),
    );
  }

  const featureDims = [];
  if (options.showFeatureDims) {
    const edgeDims = {};
    const offsetDims = {};
    item.data.fabrications?.edge?.forEach((f, i) => {
      if (!edgeDims[f.reference.loop]) edgeDims[f.reference.loop] = {};
      if (!edgeDims[f.reference.loop][f.reference.edge])
        edgeDims[f.reference.loop][f.reference.edge] = {};
      if (!edgeDims[f.reference.loop][f.reference.edge][f.reference.start])
        edgeDims[f.reference.loop][f.reference.edge][f.reference.start] = [];

      edgeDims[f.reference.loop][f.reference.edge][f.reference.start].push(f);
    });

    item.data.fabrications?.voids?.forEach((f, i) => {
      if (!edgeDims[f.reference.loop]) edgeDims[f.reference.loop] = {};
      if (!offsetDims[f.reference.loop]) offsetDims[f.reference.loop] = {};
      if (!edgeDims[f.reference.loop][f.reference.edge])
        edgeDims[f.reference.loop][f.reference.edge] = {};
      if (!offsetDims[f.reference.loop][f.reference.edge])
        offsetDims[f.reference.loop][f.reference.edge] = {};
      if (!edgeDims[f.reference.loop][f.reference.edge][f.reference.start])
        edgeDims[f.reference.loop][f.reference.edge][f.reference.start] = [];
      if (!offsetDims[f.reference.loop][f.reference.edge][f.reference.start])
        offsetDims[f.reference.loop][f.reference.edge][f.reference.start] = [];

      edgeDims[f.reference.loop][f.reference.edge][f.reference.start].push(f);
      offsetDims[f.reference.loop][f.reference.edge][f.reference.start].push(f);
    });

    Object.keys(edgeDims).forEach((loop) => {
      Object.keys(edgeDims[loop]).forEach((edge) => {
        Object.keys(edgeDims[loop][edge]).forEach((start) => {
          const features = edgeDims[loop][edge][start].sort(
            (a, b) =>
              a.reference.length.toNumber("inches") -
              b.reference.length.toNumber("inches"),
          );
          const e = edges[loop][edge];

          let pt = e[start];
          features.forEach((f) => {
            const { point } = edgePoint(e, f.reference);
            const tier = start === "end" ? 0.5 : -0.5;
            const dim = new Drawing()
              .aligned_dim(pt, point, { tier })
              .style(dimstyle);

            if (!equal(pt, point)) featureDims.push(dim);
            pt = point;
          });
        });
      });
    });

    Object.keys(offsetDims).forEach((loop) => {
      Object.keys(offsetDims[loop]).forEach((edge) => {
        Object.keys(offsetDims[loop][edge]).forEach((start) => {
          const features = offsetDims[loop][edge][start].sort(
            (a, b) =>
              a.reference.offset.toNumber("inches") -
              b.reference.offset.toNumber("inches"),
          );
          const e = edges[loop][edge];

          let pt = e[start];
          features.forEach((f) => {
            const { point } = edgeOffsetPoint(e, f.reference);
            const tier = start === "end" ? -0.5 : 0.5;
            const dim = new Drawing()
              .aligned_dim(pt, point, { tier })
              .style(dimstyle);
            if (!equal(pt, point)) featureDims.push(dim);
            pt = point;
          });
        });
      });
    });
  }

  const edgeNumbers = [];
  if (options.showEdgeNumbers) {
    edges.forEach((loop, i) => {
      loop.forEach((edge, j) => {
        const m = midpoint(edge.start, edge.end);
        const evec = normalize(subtract(edge.end, edge.start));
        const ovec = rotate(evec, Math.PI / 2);
        const offset = scale(ovec, 10);
        edgeNumbers.push(
          new Drawing()
            .text(`${j + 1}`, m, { offset })
            .style({ fill: "black" }),
        );
      });
    });
  }

  const featureLabels = [];
  if (options.showFeatureLabels) {
    if (item.data.fabrications?.voids) {
      item.data.fabrications.voids.forEach((f, i) => {
        const perimeter = polyface.shape[0];
        const edge = {
          start: perimeter[f.reference.edge],
          end: perimeter[(f.reference.edge + 1) % perimeter.length],
        };

        const { center } = edgeOffsetCenter(edge, f.reference);
        const offset =
          f.type === "circular-hole" ? -20 - f.diameter.toNumber("inches") : 0;
        featureLabels.push(
          new Drawing()
            .mark(center, `${i + 1}`, {
              shape: "circle",
              offset: { x: 0, y: offset },
            })
            .style(markStyle),
        );
      });
    }
  }

  const rakeIcons = [];
  edges.forEach((loop, i) => {
    loop.forEach((edge, j) => {
      const rake = rakeSymbol(edge);
      if (rake) rakeIcons.push(rake);
    });
  });

  let thickLines = null;
  const edgeTreatments = get(item, "data.fabrications.edge_treatment") || [];
  const edgeCounts = edges.map((l) => l.length);
  let e = 0;
  let i = 0;

  if (isShape) {
    const tl = [];
    edgeTreatments.forEach((t) => {
      if (i > edgeCounts[e].length) {
        e += 1;
        i = 0;
      }

      const edge = edges[e][i];
      if (t && edge) {
        tl.push(
          new Drawing()
            .polyline([edge.start, edge.end])
            .style({ stroke: "black", lineWidth: 3 }),
        );
      }

      i += 1;
    });
    if (tl.length > 0) {
      thickLines = new Drawing().add(...tl).mask(swf.shape);
    }
  }

  const vertDwgs = options.interactive
    ? polyface.shape
        .reduce(
          (vertices, loop, i) =>
            vertices.concat(loop.filter((v, j) => !cornerFabs[i][j])),
          [],
        )
        .map((v) =>
          new Drawing().point(v).style({
            fill: "black",
          }),
        )
    : [];

  const edgeHitboxes = edges.reduce((h, loop, i) => {
    return h.concat(
      loop.map((e, j) =>
        new Drawing().polyline([e.start, e.end]).name(`edge_${i}_${j}`),
      ),
    );
  }, []);

  const vertHitboxes = options.interactive
    ? polyface.shape.reduce((h, loop, i) => {
        return h.concat(
          loop
            .map((v, j) =>
              new Drawing().point(v, "circle", 2).name(`vertex_${i}_${j}`),
            )
            .filter((v, j) => !cornerFabs[i][j]),
        );
      }, [])
    : [];

  const voidHitboxes = polyface.voids.map((f, i) => {
    const perimeter = polyface.shape[0];
    const edge = {
      start: perimeter[f.reference.edge],
      end: perimeter[(f.reference.edge + 1) % perimeter.length],
    };

    const { center, evec, ovec } = edgeOffsetCenter(edge, f.reference);

    if (f.type === "circular-hole") {
      const diam = f.diameter.toNumber("inches");
      return new Drawing().circle(center, diam / 2).name(`void_${i}`);
    } else if (f.type === "rectangular-hole") {
      const { rot, pos, width, height } = rectPosition(center, f, evec, ovec);
      const radius = f.radius.toNumber("inches");

      return new Drawing()
        .rectangle(0, 0, width, height, radius)
        .rotate(rot)
        .translate(pos.x, pos.y)
        .name(`void_${i}`);
    }
  });

  const fabricationHitboxes = [];
  if (isShape) {
    polyface.surfaceFabrications.forEach((f, i) => {
      const edge = edges[f.reference.loop][f.reference.edge];
      const verts = edgeRectanglePoints(edge, f);
      fabricationHitboxes.push(
        new Drawing()
          .polyface([verts])
          .name(`bug_${f.reference.loop}_${f.reference.edge}`),
      );
    });

    polyface.edgeFabrications.forEach((f, i) => {
      const edge = edges[f.reference.loop][f.reference.edge];
      const { point, rotation } = edgePoint(edge, f.reference);
      const fab = fabrications[f.fab_id];
      const { path, voids, annotations } = fabPath(f, fab);

      if (path) {
        fabricationHitboxes.push(
          new Drawing()
            .polyline(path)
            .polyface(voids.map((v) => v.path))
            .rotate(rotation)
            .translate(point.x, point.y)
            .name(`efab_${i}`),
        );
      }

      if (annotations) {
        fabricationHitboxes.push(
          annotations
            .rotate(rotation)
            .translate(point.x, point.y)
            .name(`efab_${i}`),
        );
      }

      fabMarks.push(
        new Drawing()
          .mark(point, fab.mark, { shape: "circle" })
          .style(markStyle),
      );
    });
    polyface.cornerFabrications.forEach((f, i) => {
      const loop = polyface.shape[f.reference.loop];
      const prev = loop[(f.reference.corner + loop.length - 1) % loop.length];
      const corner = loop[f.reference.corner];
      const next = loop[(f.reference.corner + 1) % loop.length];
      const fab = fabrications[f.fab_id];
      const { path, voids, annotations } = fabPath(f, fab);
      const { flipHorizontal, flipVertical } = cornerFlip(prev, corner, next);
      if (path) {
        fabricationHitboxes.push(
          new Drawing()
            .polyline(path)
            .polyface(voids.map((v) => v.path))
            .scale(flipHorizontal, flipVertical)
            .translate(corner.x, corner.y)
            .name(`cfab_${i}`),
        );
      }

      if (annotations) {
        fabricationHitboxes.push(
          annotations
            .scale(flipHorizontal, flipVertical)
            .translate(corner.x, corner.y)
            .name(`cfab_${i}`),
        );
      }

      fabMarks.push(
        new Drawing()
          .mark(corner, fab.mark, { shape: "circle" })
          .style(markStyle),
      );
    });
  }

  let highlights = [];
  let fDims = [];
  if (options.highlights) {
    if (options.highlights.edges) {
      options.highlights.edges.forEach((loop, l) => {
        loop.forEach((e) => {
          const edge = edges[l][e];

          highlights.push(
            new Drawing()
              .polyline([edge.start, edge.end])
              .style({ stroke: "#009BFA", lineWidth: 3 }),
          );

          // show arc height dimension
          if (edge.end.bulge) {
            const s = bulgeSagitta(edge.start, edge.end);
            const ovec = rotate(
              normalize(subtract(edge.end, edge.start)),
              -Math.PI / 2,
            );
            const o = add(edge.start, scale(ovec, s));

            const callback = (v) => {
              const sign = Math.sign(edge.end.bulge);
              const bulge = sagittaBulge(edge.start, edge.end, v);
              const vi = (e + 1) % edges[l].length;

              return [
                {
                  path: `shape.vertices.${l}.${vi}.bulge`,
                  value: bulge * sign,
                },
              ];
            };

            const dim = new Drawing()
              .aligned_dim(edge.start, o, { tier: -1, callback })
              .style(dimstyle)
              .name(`dim_edge_${l}_${e}_bulge`);

            fDims.push(dim);
            highlights.push(dim);
          }
        });
      });
    }

    if (options.highlights.vertices) {
      options.highlights.vertices.forEach((loop, l) => {
        loop.forEach((v) => {
          const vertex = polyface.shape[l][v];

          highlights.push(
            new Drawing()
              .point(vertex, "circle", 1.5)
              .style({ fill: "#009BFA", stroke: "#009BFA" }),
          );
        });
      });
    }

    if (options.highlights.voids) {
      options.highlights.voids.forEach((f) => {
        const edge = edges[0][f.reference.edge];
        const { center, dimensions, evec, ovec } = edgeOffsetCenter(
          edge,
          f.reference,
        );

        if (f.type === "circular-hole") {
          const diam = f.diameter.toNumber("inches");

          highlights.push(
            new Drawing()
              .circle(center, diam / 2)
              .style({ fill: "transparent", stroke: "#009BFA", lineWidth: 3 }),
          );
        } else if (f.type === "rectangular-hole") {
          const { rot, pos, width, height } = rectPosition(
            center,
            f,
            evec,
            ovec,
          );
          const radius = f.radius.toNumber("inches");

          highlights.push(
            new Drawing()
              .rectangle(0, 0, width, height, radius)
              .rotate(rot)
              .translate(pos.x, pos.y)
              .style({ fill: "transparent", stroke: "#009BFA", lineWidth: 3 }),
          );
        }

        dimensions.forEach((dim, i) => {
          const d = new Drawing()
            .aligned_dim(dim.start, dim.end, {
              tier: dim.tier,
              callback: dim.callback(`fabrications.voids.${f.id}`),
            })
            .style(dimstyle)
            .name(
              `dtdim_feature_${f.id}_${i}|fabrications.voids.${f.id}.${dim.path}`,
            );

          fDims.push(d);
          highlights.push(d);
        });
      });
    }

    if (options.highlights.bug) {
      const f = options.highlights.bug;
      const edge = edges[f.reference.loop][f.reference.edge];
      const verts = edgeRectanglePoints(edge, f);
      const { dimensions } = edgeOffsetCenter(edge, f.reference);

      highlights.push(
        new Drawing()
          .polyface([verts])
          .style({ fill: "transparent", stroke: "#009BFA", lineWidth: 3 }),
      );

      dimensions.forEach((dim, i) => {
        const d = new Drawing()
          .aligned_dim(dim.start, dim.end, {
            tier: dim.tier,
            callback: dim.callback("fabrications.bug"),
          })
          .style(dimstyle)
          .name(`dtdim_feature_bug_${i}|fabrications.bug.${dim.path}`);

        fDims.push(d);
        highlights.push(d);
      });
    }

    if (options.highlights.efabs) {
      options.highlights.efabs.forEach((f) => {
        const edge = edges[f.reference.loop][f.reference.edge];
        const { point, rotation, dimensions } = edgePointRotation(
          edge,
          f.reference,
        );
        const fab = fabrications[f.fab_id];
        const { path, voids, annotations } = fabPath(f, fab);

        if (path) {
          highlights.push(
            new Drawing()
              .polyline(path)
              .polyface(voids.map((v) => v.path))
              .rotate(rotation)
              .translate(point.x, point.y)
              .style({ fill: "transparent", stroke: "#009BFA", lineWidth: 3 }),
          );
        }

        if (annotations) {
          const p = annotations
            .rotate(rotation)
            .translate(point.x, point.y)
            .style({ fill: "transparent", stroke: "#0098FA", lineWidth: 3 });

          highlights.push(p);
        }

        dimensions.forEach((dim) => {
          const d = new Drawing()
            .aligned_dim(dim.start, dim.end, {
              tier: dim.tier,
              callback: dim.callback(f.id),
            })
            .style(dimstyle)
            .name(
              `dtdim_feature_${f.id}_${i}|fabrications.edge.${f.id}.${dim.path}`,
            );

          fDims.push(d);
          highlights.push(d);
        });
      });
    }

    if (options.highlights.cfabs) {
      options.highlights.cfabs.forEach((f) => {
        const loop = polyface.shape[f.reference.loop];
        const prev = loop[(f.reference.corner + loop.length - 1) % loop.length];
        const corner = loop[f.reference.corner];
        const next = loop[(f.reference.corner + 1) % loop.length];
        const fab = fabrications[f.fab_id];
        const { path, voids, annotations } = fabPath(f, fab);
        const { flipHorizontal, flipVertical } = cornerFlip(prev, corner, next);

        if (path) {
          highlights.push(
            new Drawing()
              .polyline(path)
              .polyface(voids.map((v) => v.path))
              .scale(flipHorizontal, flipVertical)
              .translate(corner.x, corner.y)
              .style({ fill: "transparent", stroke: "#009BFA", lineWidth: 3 }),
          );
        }

        if (annotations) {
          const p = annotations
            .scale(flipHorizontal, flipVertical)
            .translate(corner.x, corner.y)
            .style({ fill: "transparent", stroke: "#0098FA", lineWidth: 3 });

          highlights.push(p);
        }
      });
    }
  }

  if (options.tempPt) {
    highlights.push(
      new Drawing().point(options.tempPt).style({ fill: "black" }),
    );
  }

  if (options.currentFeature) {
    const f = options.currentFeature;
    if (f.type === "circular-hole") {
      const edge = edges[0][f.reference.edge];
      const { center, dimensions } = edgeOffsetCenter(edge, f.reference);
      const diam = f.diameter.toNumber("inches");
      highlights.push(
        new Drawing()
          .circle(center, diam / 2)
          .style({ fill: "transparent", stroke: "#009BFA", lineWidth: 2 }),
      );

      dimensions.forEach((dim) => {
        highlights.push(
          new Drawing()
            .aligned_dim(dim.start, dim.end, { tier: dim.tier })
            .style(dimstyle),
        );
      });
    } else if (f.type === "rectangular-hole") {
      const edge = edges[0][f.reference.edge];
      const { center, dimensions, evec, ovec } = edgeOffsetCenter(
        edge,
        f.reference,
      );
      const { rot, pos, width, height } = rectPosition(center, f, evec, ovec);
      const radius = f.radius.toNumber("inches");

      highlights.push(
        new Drawing()
          .rectangle(0, 0, width, height, radius)
          .rotate(rot)
          .translate(pos.x, pos.y)
          .style({ fill: "transparent", stroke: "#009BFA", lineWidth: 2 }),
      );

      dimensions.forEach((dim) => {
        highlights.push(
          new Drawing()
            .aligned_dim(dim.start, dim.end, { tier: dim.tier })
            .style(dimstyle),
        );
      });
    } else if (f.type === "edge-fabrication") {
      const edge = edges[f.reference.loop][f.reference.edge];
      const { point, rotation, dimensions } = edgePointRotation(
        edge,
        f.reference,
      );
      const fab = fabrications[f.fab_id];
      const { path, voids, annotations } = fabPath(f, fab);

      if (path) {
        const shape = new Drawing()
          .polyline(path)
          .polyface(voids.map((v) => v.path))
          .rotate(rotation)
          .translate(point.x, point.y)
          .style({ fill: "transparent" });

        highlights.push(shape);
      }

      if (annotations) {
        const positioned = annotations
          .rotate(rotation)
          .translate(point.x, point.y);

        highlights.push(positioned);
      }

      dimensions.forEach((dim) => {
        highlights.push(
          new Drawing()
            .aligned_dim(dim.start, dim.end, { tier: dim.tier })
            .style(dimstyle),
        );
      });
    } else if (f.type === "corner-fabrication") {
      const loop = polyface.shape[f.reference.loop];
      const prev = loop[(f.reference.corner + loop.length - 1) % loop.length];
      const corner = loop[f.reference.corner];
      const next = loop[(f.reference.corner + 1) % loop.length];
      const fab = fabrications[f.fab_id];
      const { path, voids, annotations } = fabPath(f, fab);
      const { flipHorizontal, flipVertical } = cornerFlip(prev, corner, next);

      if (path) {
        const shape = new Drawing()
          .polyline(path)
          .polyface(voids.map((v) => v.path))
          .scale(flipHorizontal, flipVertical)
          .translate(corner.x, corner.y)
          .style({ fill: "transparent" });

        highlights.push(shape);
      }

      if (annotations) {
        const positioned = annotations
          .scale(flipHorizontal, flipVertical)
          .translate(corner.x, corner.y);

        highlights.push(positioned);
      }
    }
  }

  let voidLines = [];

  if (isShape && options.isCollection) {
    voidLines.push(
      new Drawing()
        .polyline([
          { x: xmin, y: ymin },
          { x: xmax, y: ymax },
        ])
        .polyline([
          { x: xmin, y: ymax },
          { x: xmax, y: ymin },
        ])
        .style({ stroke: "#bbb" })
        .mask(swf.shape),
    );
  }

  const dwgs = [
    context,
    shape,
    ...surfaceFeatures,
    offsets,
    ...refPlanes,
    ...voidLines,
    ...swf.annotations,
    thickLines,
    ...fabMarks,
    mark,
    ...rakeIcons,
    ...edgeNumbers,
    ...featureLabels,
    ...(type.mark ? [typeMark] : []),
    ...featureDims,
    ...xDims,
    ...yDims,
    ...vertDwgs,
  ];

  const hbDwgs = [
    shape,
    ...edgeHitboxes,
    ...voidHitboxes,
    ...fabricationHitboxes,
  ];

  if (options.interactive) {
    hbDwgs.push(mark);
    if (type.mark) hbDwgs.push(typeMark);

    hbDwgs.push(...rpHitBoxes, ...vertHitboxes, ...xDims, ...yDims, ...fDims);
  }

  return {
    drawing: new Drawing().add(...dwgs, ...highlights),
    hitboxDrawing: new Drawing().add(...hbDwgs),
  };
}

export default liteDrawingDetailed;
